import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { setHasSelectionAction } from '../../../state/actions/bulk-selection';
import { LOCK_FIELD, UNLOCK_ALL_FIELDS, UNLOCK_FIELD } from '../../../state/actions/field/lock';
import { UPDATE_PROVIDED_ISSUE_KEYS } from '../../../state/actions/issues';
import type { State } from '../../../state/reducers/types';
import { getSelectionServiceActions } from '../../../state/selectors/bulk-selection';
import { mergeIssueKeysWithLockedIssues } from '../../../state/selectors/issues';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(UNLOCK_FIELD, LOCK_FIELD, UNLOCK_ALL_FIELDS, UPDATE_PROVIDED_ISSUE_KEYS)
		.switchMap(() => {
			const state = store.getState();
			const selectionServiceActions = getSelectionServiceActions(state);
			if (!selectionServiceActions) {
				log.safeErrorWithoutCustomerData(
					'issue-table.ops.bulk-select.refresh-selection',
					'selectionServiceActions is not set.',
				);
				fireErrorAnalytics({
					meta: {
						id: 'opsRefreshSelection',
						packageName: 'jiraIssueTable',
						teamName: 'jsd-shield',
					},
				});
				return Observable.empty<never>();
			}

			const { refreshSelection } = selectionServiceActions;
			const issues = mergeIssueKeysWithLockedIssues(state);
			refreshSelection(issues.map(toIssueKey));
			return Observable.of(setHasSelectionAction({ hasSelection: issues.length > 0 }));
		});
