import { ff } from '@atlassian/jira-feature-flagging';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type VERSION_GQL_FIELD,
	ISSUE_FIELD_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';
import {
	transformAggVersionToLegacyVersion,
	transformAggVersionToLegacyVersionOld,
} from './version-node-transformer';

export const transformAggSingleVersionPickerToLegacyField: AggTransformerFunction<
	typeof VERSION_GQL_FIELD
> = (node) => {
	if (node.type !== VERSION_CF_TYPE) return undefined;

	const allowedValues = transformAggConnection(
		node.singlePickerVersions,
		ff('issue.details.relay-release-version-field-issue-view-integration')
			? transformAggVersionToLegacyVersion
			: transformAggVersionToLegacyVersionOld,
	);

	const transformVersion = ff('issue.details.relay-release-version-field-issue-view-integration')
		? transformAggVersionToLegacyVersion
		: transformAggVersionToLegacyVersionOld;

	const value = node.version ? transformVersion(node.version) : null;

	return {
		...transformAggNodeToLegacyField(node),
		allowedValues,
		value,
		schema: {
			configuration: null,
			items: null,
			renderer: PLAIN_TEXT,
			type: ISSUE_FIELD_VERSION_CF_TYPE,
			custom: VERSION_CF_TYPE,
			system: null,
		},
	};
};
