import { merge, updateIn, assoc, assocIn } from 'icepick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import unsetIn from '@atlassian/jira-common-icepick/src/utils/unset-in/index.tsx';
import type { LockedIssue } from '../../../model/locking';
import { UPDATE_COLUMNS, type UpdateColumnsAction } from '../../actions/columns';
import {
	LOCK_FIELD,
	UNLOCK_FIELD,
	UNLOCK_ALL_FIELDS,
	type LockFieldAction,
	type UnlockFieldAction,
	type UnlockAllFieldsAction,
} from '../../actions/field/lock';
import {
	UPDATE_PROVIDED_ISSUE_KEYS,
	type UpdateProvidedIssueKeysAction,
} from '../../actions/issues';
import {
	SET_HORIZONTAL_OFFSET,
	SET_VERTICAL_OFFSET,
	type SetHorizontalOffsetAction,
	type SetVerticalOffsetAction,
} from '../../actions/offsets';
import { SET_ROW_LIST_REF, type SetRowListRefAction } from '../../actions/table';
import type { PersistedState } from './types';

type Actions =
	| UnlockFieldAction
	| LockFieldAction
	| UnlockAllFieldsAction
	| UpdateColumnsAction
	| UpdateProvidedIssueKeysAction
	| SetHorizontalOffsetAction
	| SetVerticalOffsetAction
	| SetRowListRefAction;

const DEFAULT_PERSISTED_STATE: PersistedState = {
	columns: [],
	providedIssueKeys: [],
	lockedIssues: {},
	offsets: {
		verticalScrollOffset: 0,
		horizontalScrollOffset: 0,
	},
	rowListRef: undefined,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: PersistedState = DEFAULT_PERSISTED_STATE, action: Actions) => {
	switch (action.type) {
		case UNLOCK_FIELD: {
			const { issueKey, fieldId } = action.payload;
			const newLockedIssues = unsetIn(state, ['lockedIssues', issueKey, 'fields', fieldId]);
			const newLockedIssue = newLockedIssues.lockedIssues[issueKey];
			if (newLockedIssue && isEmpty(newLockedIssue.fields)) {
				return unsetIn(state, ['lockedIssues', issueKey]);
			}
			return newLockedIssues;
		}
		case LOCK_FIELD: {
			const { issueKey, fieldId, positionIndex } = action.payload;
			return updateIn(state, ['lockedIssues'], (lockedIssues) => {
				// Clean up any other issues with the same position
				const newLockedIssues = omitBy(
					// @ts-expect-error - TS2769 - No overload matches this call.
					lockedIssues,
					(lockedIssue: LockedIssue) =>
						lockedIssue.positionIndex === positionIndex && lockedIssue.issueKey !== issueKey,
				);
				// Overwrite/update the current locked issue details
				return updateIn(newLockedIssues, [issueKey], (lockedIssue: LockedIssue) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const fields: Record<string, any> = {};
					fields[fieldId] = true;
					return merge(lockedIssue || {}, {
						issueKey,
						positionIndex,
						fields,
					});
				});
			});
		}
		case UNLOCK_ALL_FIELDS: {
			return assoc(state, 'lockedIssues', {});
		}

		case UPDATE_COLUMNS: {
			const { columns } = action.payload;
			if (isEqual(state.columns, columns)) {
				return state;
			}
			return assoc(state, 'columns', columns);
		}
		case UPDATE_PROVIDED_ISSUE_KEYS: {
			const { issueKeys } = action.payload;
			return assoc(state, 'providedIssueKeys', issueKeys);
		}
		case SET_HORIZONTAL_OFFSET: {
			const { horizontalOffset } = action.payload;
			return assocIn(state, ['offsets', 'horizontalScrollOffset'], horizontalOffset);
		}
		case SET_VERTICAL_OFFSET: {
			const { verticalOffset } = action.payload;
			return assocIn(state, ['offsets', 'verticalScrollOffset'], verticalOffset);
		}
		case SET_ROW_LIST_REF: {
			return assoc(state, 'rowListRef', action.payload);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
