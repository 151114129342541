import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type DATE_PICKER_GQL_FIELD,
	DATE_CF_TYPE,
	BASELINE_START_CF_TYPE,
	BASELINE_END_CF_TYPE,
	DUE_DATE_TYPE,
	ISSUE_FIELD_DATE_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggDatePickerToLegacyField: AggTransformerFunction<
	typeof DATE_PICKER_GQL_FIELD
> = (node) => {
	switch (node.type) {
		case DUE_DATE_TYPE:
			return {
				...transformAggNodeToLegacyField(node),
				value: typeof node.date === 'string' ? node.date : null,
				schema: {
					type: ISSUE_FIELD_DATE_CF_TYPE,
					configuration: null,
					system: node.type,
					renderer: PLAIN_TEXT,
					custom: null,
					items: null,
				},
			};
		case DATE_CF_TYPE:
		case BASELINE_START_CF_TYPE:
		case BASELINE_END_CF_TYPE:
			return {
				...transformAggNodeToLegacyField(node),
				value: typeof node.date === 'string' ? node.date : null,
				schema: {
					type: ISSUE_FIELD_DATE_CF_TYPE,
					custom: node.type,
					configuration: null,
					renderer: PLAIN_TEXT,
					system: null,
					items: null,
				},
			};
		default:
			return undefined;
	}
};
