export const INITIAL_LOAD_ALL = 'initialLoadAll';
export const INITIAL_LOAD_SSR_HIT = 'initialLoadSsrHit';
export const INITIAL_LOAD_SSR_ERROR = 'initialLoadSsrError';
export const INITIAL_LOAD_CACHE_HIT = 'initialLoadCacheHit';
export const INITIAL_BROWSER_ISSUE_LIST_FETCH = 'initialLoadBrowserIssueListFetch';

export type InitialLoadSSrAnalyticEvent =
	| typeof INITIAL_LOAD_ALL
	| typeof INITIAL_LOAD_SSR_HIT
	| typeof INITIAL_LOAD_SSR_ERROR
	| typeof INITIAL_LOAD_CACHE_HIT
	| typeof INITIAL_BROWSER_ISSUE_LIST_FETCH;
