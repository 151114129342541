import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { useThemeObserver } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '../../../../../constants.tsx';
import { EMPTY_STATE_DATA } from './constants';
import messages from './messages';
import { Description } from './styled';

export const EmptyQueueAdminView = () => {
	const { formatMessage } = useIntl();
	const baseUrl = '';
	const [practice] = usePathParam('practiceType');
	const [projectKey] = usePathParam('projectKey');
	const { colorMode } = useThemeObserver();
	// practice should always be set in case of an unconfigured queue, if not this is a wrong route
	if (practice === undefined) {
		return null;
	}

	const { headerMessage, descriptionMessage, getImageUrl, learnMoreText, learnMoreUrl } =
		EMPTY_STATE_DATA[toItsmPractice(practice)];

	const getHref = () => {
		if (projectKey == null) {
			return undefined;
		}
		return `${baseUrl}/jira/servicedesk/projects/${projectKey}/settings/request-types/category/${practice}`;
	};

	return (
		<EmptyState
			header={formatMessage(headerMessage)}
			description={
				<Description>
					<FormattedMessage
						{...descriptionMessage}
						values={{
							p: (chunks) => <p>{chunks}</p>,
							a: () => (
								<a target="_blank" href={learnMoreUrl}>
									{learnMoreText && formatMessage(learnMoreText)}
								</a>
							),
						}}
					/>
				</Description>
			}
			imageUrl={getImageUrl(colorMode)}
			primaryAction={
				<Button appearance="primary" href={getHref()}>
					{formatMessage(messages.addRequestTypeButtonText)}
				</Button>
			}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			testId="servicedesk-queues-empty-queue.common.ui.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.empty-queue-admin-view"
		/>
	);
};
