import type {
	MediaInlineDefinition,
	DocNode as ADF,
	MediaDefinition as MediaNode,
} from '@atlaskit/adf-schema';
import { traverse, filter } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity, VisitorCollection } from '@atlaskit/adf-utils/types';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';

type MediaNodeType = {
	type: MediaNode['type'];
	attrs: MediaNode['attrs'] & { id: string; collection?: Object };
	marks?: MediaNode['marks'];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ADFType = (Pick<ADF, 'version' | 'type' | 'content'> & { content: Array<any> }) | null;

export const emptyAdfObject: ADF = {
	type: 'doc',
	version: 1,
	content: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeCollectionFromAdf = (adf: any) => {
	if (adf === null || adf === undefined) {
		return null;
	}
	if (adf.type === 'media' && adf.attrs) {
		return {
			...adf,
			attrs: { ...adf.attrs, collection: '' },
		};
	}
	if (ff('issue.details.media-inline') && adf.type === 'mediaInline' && adf.attrs) {
		return {
			...adf,
			attrs: { ...adf.attrs, collection: '' },
		};
	}
	if (adf.content) {
		return {
			...adf,
			content: adf.content.map(removeCollectionFromAdf),
		};
	}

	return adf;
};

export const convertAdfStringToObject = (adf: string | null) => {
	if (typeof adf === 'string') {
		try {
			return removeCollectionFromAdf(JSON.parse(adf));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				'rich-content.convert-adf-to-object',
				'Failed to parse rich content',
				error,
			);
			return null;
		}
	}
	return removeCollectionFromAdf(adf);
};

export const isOnlyWhitespaceAdf = (adf?: ADF | null): boolean =>
	adf && adf.content
		? adf.content.every((content) => {
				if (content.type === 'paragraph') {
					return (
						!content.content ||
						content.content.length === 0 ||
						content.content.every((innerContent) =>
							innerContent.type === 'text'
								? (innerContent.text || '').match(/^\s*$/) !== null
								: false,
						)
					);
				}
				return false;
			})
		: false;

export const removeNewLineCharactersFromAdf = (adf?: ADFType): ADF => {
	if (!adf || !adf.content) return emptyAdfObject;

	let len;
	for (
		len = adf.content.length - 1;
		len >= 0 &&
		adf.content[len].content &&
		adf.content[len].type === 'paragraph' &&
		adf.content[len].content.length === 0;
		len -= 1
	) {
		// if the last item of the node is of type mediaGroup, we don't want to strip newline character as
		// it practically makes it unclickable. Leave a new line character so there is still some space for ppl to click
		if (len > 0 && adf.content[len - 1].type === 'mediaGroup') {
			break;
		}
	}

	return {
		...adf,
		content: adf.content.slice(0, len + 1),
	};
};

export const removeTrailingNewLineCharacters = (text: string): string =>
	text.replace(/[\r\n]+$/, '');
export const simplifyAdf = (adf: ADF): ADF => (isOnlyWhitespaceAdf(adf) ? emptyAdfObject : adf);

export const hasMediaFileNodes = (doc: ADF): boolean => {
	if (ff('issue.details.media-inline')) {
		const mediaNodes = new Map<string, ADFEntity>();
		traverse(doc, {
			media: (node: ADFEntity) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const mediaNode = node as MediaNode;
				if (mediaNode.attrs.type === 'external') {
					return;
				}
				mediaNodes.set(mediaNode.attrs.id, node);
			},
			mediaInline: (node: ADFEntity) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const mediaInlineNode = node as MediaInlineDefinition;
				mediaNodes.set(mediaInlineNode.attrs.id, node);
			},
		});
		return mediaNodes.size > 0;
	}

	const mediaNodes = new Map<string, MediaNode>();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	traverse(doc, {
		media: (node: MediaNodeType) => {
			if (!node.attrs.id || node.attrs.collection === undefined) {
				return;
			}
			mediaNodes.set(node.attrs.id, node);
		},
	} as unknown as VisitorCollection);
	return mediaNodes.size > 0;
};

export const hasMediaFileNodesUpdated = (currentDoc?: ADF | null, newDoc?: ADF | null) => {
	if (ff('issue.details.media-inline')) {
		const currentMediaNodes = new Set<string>();
		const newMediaNodes = new Set<string>();
		const updatedMediaNodes = new Set<string>();

		if (newDoc) {
			traverse(newDoc, {
				media: (node: ADFEntity) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const mediaNode = node as MediaNode;
					if (mediaNode.attrs.type === 'external') {
						return;
					}
					newMediaNodes.add(mediaNode.attrs.id);
				},
				mediaInline: (node: ADFEntity) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const mediaInlineNode = node as MediaInlineDefinition;
					newMediaNodes.add(mediaInlineNode.attrs.id);
				},
			});
		}

		if (currentDoc) {
			traverse(currentDoc, {
				media: (node: ADFEntity) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const mediaNode = node as MediaNode;
					if (mediaNode.attrs.type === 'external') {
						return;
					}
					currentMediaNodes.add(mediaNode.attrs.id);

					if (newMediaNodes.size) {
						const matchedNode = newMediaNodes.has(mediaNode.attrs.id);

						if (!matchedNode) {
							updatedMediaNodes.add(mediaNode.attrs.id);
						}
					}
				},
				mediaInline: (node: ADFEntity) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const mediaInlineNode = node as MediaInlineDefinition;
					currentMediaNodes.add(mediaInlineNode.attrs.id);

					if (newMediaNodes.size) {
						const matchedNode = newMediaNodes.has(mediaInlineNode.attrs.id);

						if (!matchedNode) {
							updatedMediaNodes.add(mediaInlineNode.attrs.id);
						}
					}
				},
			});
		}

		return currentMediaNodes.size !== newMediaNodes.size || !!updatedMediaNodes.size;
	}

	const currentMediaNodes = new Set<string>();
	const newMediaNodes = new Set<string>();
	const updatedMediaNodes = new Set<string>();

	if (newDoc) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		traverse(newDoc, {
			media: (node: MediaNodeType) => {
				if (!node.attrs.id || node.attrs.collection === undefined) {
					return;
				}
				newMediaNodes.add(node.attrs.id);
			},
		} as unknown as VisitorCollection);
	}

	if (currentDoc) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		traverse(currentDoc, {
			media: (node: MediaNodeType) => {
				if (!node.attrs.id || node.attrs.collection === undefined) {
					return;
				}
				currentMediaNodes.add(node.attrs.id);

				if (newMediaNodes.size) {
					const matchedNode = newMediaNodes.has(node.attrs.id);

					if (!matchedNode) {
						updatedMediaNodes.add(node.attrs.id);
					}
				}
			},
		} as unknown as VisitorCollection);
	}

	return currentMediaNodes.size !== newMediaNodes.size || !!updatedMediaNodes.size;
};

export const hasOnlySimpleText = (doc?: ADF | null): boolean => {
	if (doc === null || doc === undefined) {
		return false;
	}
	const nonSimpleTextNodes = filter(
		doc,
		(node) => node.type !== 'paragraph' && node.type !== 'doc' && node.type !== 'text',
	);
	return nonSimpleTextNodes.length === 0;
};

export const convertAdfToMarkup = async (node?: ADF | null): Promise<string> => {
	// the imports here were kept async so as not to blow the bundle sizes for everyone
	const { defaultSchema } = await import(
		/* webpackChunkName: "async-adf-defaultschema" */ '@atlaskit/adf-schema/schema-default'
	);
	const { WikiMarkupTransformer } = await import(
		/* webpackChunkName: "async-wikimarkup-transformer" */ '@atlaskit/editor-wikimarkup-transformer'
	);
	const { JSONTransformer } = await import(
		/* webpackChunkName: "async-json-transformer" */ '@atlaskit/editor-json-transformer'
	);

	const jsonTransformer = new JSONTransformer();
	const wikiMarkupTransformer = new WikiMarkupTransformer(defaultSchema);

	if (node === null || node === undefined) {
		return '';
	}

	const pmNode = jsonTransformer.parse(node);
	return wikiMarkupTransformer.encode(pmNode);
};
