import { ff } from '@atlassian/jira-feature-flagging';
import { FROTHER } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Component } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import {
	type COMPONENTS_GQL_FIELD,
	COMPONENTS_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';

// @DoNotExport Flow is deriving component's type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformJiraComponentToLegacyComponent = (component: any): Component | null => {
	if (component == null) return null;

	return {
		id: component.componentId,
		name: component.name,
		...(ff('compass-components-in-jira-components_xw42u', false)
			? { ari: component.ari, metadata: component.metadata }
			: {}),
	};
};

export const transformAggComponentsToLegacyField: AggTransformerFunction<
	typeof COMPONENTS_GQL_FIELD
> = (node) => {
	if (node.type !== COMPONENTS_TYPE) return undefined;

	const allowedValues = transformAggConnection(
		node.components,
		transformJiraComponentToLegacyComponent,
	);
	const value = transformAggConnection(
		node.selectedComponentsConnection,
		transformJiraComponentToLegacyComponent,
	);

	return {
		...transformAggNodeToLegacyField(node),
		allowedValues,
		value,
		schema: {
			configuration: null,
			items: 'component',
			renderer: FROTHER,
			type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
			custom: null,
			system: COMPONENTS_TYPE,
		},
	};
};
