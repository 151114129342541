import { connect } from '../../../../common/table-redux';
import type { RowId, LoadingRowRenderReasonType } from '../../../../model/rows';
import { getRowHeight } from '../../../../state/consumer/selectors/rows/index.tsx';
import { getTableWidth } from '../../../../state/internal/selectors.tsx';
// eslint-disable-next-line import/order
import {
	getOffsetFromLeft,
	getOffsetFromRight,
	getNumberOfLoadingRowsInDisplayBoundary,
	isActiveRowItem,
	isHorizontalScrollRightShadowVisible,
	getVirtualBoundaries,
} from '../../../../state/selectors';

import type { State } from '../../../../state/types';
import LoadingRow from './view';

type OwnProps = {
	id: RowId;
	reasonType: LoadingRowRenderReasonType;
	renderSidebarIcon: (issueKey: string) => React.ReactNode;
};

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 1.
export default connect((state: State, ownProps: OwnProps) => ({
	virtualBoundaries: getVirtualBoundaries(state),
	numberOfLoadingRows: getNumberOfLoadingRowsInDisplayBoundary(state),
	rowHeight: getRowHeight(state, ownProps.id),
	tableWidth: getTableWidth(state),
	tableHasRightShadow: isHorizontalScrollRightShadowVisible(state),
	isActiveItem: isActiveRowItem(state, ownProps.id),
	isRowLeftEdgeVisible: getOffsetFromLeft(state) === 0,
	isRowRightEdgeVisible: getOffsetFromRight(state) === 0,
	renderSidebarIcon: ownProps.renderSidebarIcon,
}))(LoadingRow);
