import type { SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { TableRef } from '@atlassian/jira-virtual-table/src/app/table/types.tsx';
import type {
	RowListFocusFunctionChangedCallback,
	ReactPortalContainerRefChangedCallback,
} from '@atlassian/jira-virtual-table/src/model/callbacks/index.tsx';
import type { Props } from '../../model';

type DefaultProps = {
	currentPage: number;
	totalPages: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPageChange: (arg1: SyntheticEvent<any>, arg2: number, arg3: UIAnalyticsEvent) => void;
};

export const defaultProps: DefaultProps = {
	currentPage: 1,
	totalPages: 1,
	onPageChange: noop,
};

export type PropsWithDefaults = // Remove the non-optional default props from Props
	Partial<Flow.Diff<Props, DefaultProps>> &
		Partial<DefaultProps> & {
			tableRef: (arg1: TableRef | null) => void;
			onTableFocusFunctionChanged: RowListFocusFunctionChangedCallback;
			onTableRefChanged: ReactPortalContainerRefChangedCallback;
		};
