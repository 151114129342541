// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import { toQueueId, type Column } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type {
	Page,
	PageResponseColumn,
} from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import type { PrefetcherPageResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/page/types';
import type { SortedQueues } from '../../model';

const transformColumns = (responseColumns: PageResponseColumn[]): Column[] =>
	responseColumns.map((responseColumn) => {
		const column = {
			id: responseColumn.fieldId,
			title: responseColumn.title,
			fieldType: responseColumn.fieldType,
			sortable: responseColumn.sortable,
		};

		if (responseColumn.sortOrder) {
			return {
				...column,
				currentSorting: responseColumn.sortOrder,
			};
		}

		return column;
	});

export const pageToQueues = (page: Page[]): SortedQueues =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	page.reduce<Record<string, any>>((acc, queue) => {
		acc[queue.id] = {
			id: toQueueId(queue.id),
			name: queue.name,
			jql: queue.jql,
			completeJql: queue.completeJql,
			order: queue.order,
			columns: transformColumns(queue.columnsDetails),
			// British spelling from legacy Backend code
			isFavorite: queue.favourite,
		};
		return acc;
	}, {});

export const getPrefetchedPagePromise = (): MaybeType<Promise<PrefetcherPageResponse>> =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	Maybe.fromNull(window.JSD_QUEUES_PRELOAD_PROMISES && window.JSD_QUEUES_PRELOAD_PROMISES.queues);

export const unsetPrefetchedPagePromise = (): void => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.JSD_QUEUES_PRELOAD_PROMISES) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.JSD_QUEUES_PRELOAD_PROMISES.queues = undefined;
	}
};
