import type { Vote } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import {
	type VOTES_GQL_FIELD,
	VOTES_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggJiraVotes } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

const transformAggVoteToLegacyVote = (vote: AggJiraVotes): Vote => ({
	votes: vote.count != null ? Number(vote.count) : 0,
	hasVoted: vote.hasVoted ?? false,
});

export const transformAggVotesToLegacyField: AggTransformerFunction<typeof VOTES_GQL_FIELD> = (
	node,
) => {
	if (node.type !== VOTES_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: node.vote ? transformAggVoteToLegacyVote(node.vote) : null,
		schema: {
			configuration: null,
			custom: null,
			items: null,
			renderer: null,
			system: VOTES_TYPE,
			type: VOTES_TYPE,
		},
	};
};
