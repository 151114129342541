import React from 'react';
import { useFilteredItems } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/use-filtered-connect-items';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/types.tsx';
import { ConnectItem } from './connect-item';

export type ConnectMenuProps = {
	hasSeparator?: boolean;
	shouldFilter?: boolean;
	items: ConnectDataItem[] | null | undefined;
	showIcons?: boolean;
};

export const ConnectMenu = ({
	items,
	showIcons = false,
	shouldFilter = true,
}: ConnectMenuProps) => {
	const filteredItems = useFilteredItems(items || null, shouldFilter);
	if (filteredItems == null || !filteredItems.length) return null;

	return (
		<>
			{filteredItems.map((item) => (
				<ConnectItem key={item.id} item={item} showIcons={showIcons} />
			))}
		</>
	);
};
