import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type IssueType,
	ISSUE_TYPE_HIERARCHY_LEVEL,
} from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { AggJiraIssueType } from '../types/fields-type.tsx';
import type { AggFieldConnectionEdge, AggFieldConnectionEdges } from '../types/issue-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
// eslint-disable-next-line import/order
import {
	ISSUE_TYPE,
	ISSUE_TYPE_GQL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

import { transformAggNodeToLegacyField } from './default-node-transformer';

// @ExportForTesting - Not used in Bento; added for parity with legacy
export const deprecatedAttributes = {
	avatarId: 0,
	self: '', // @deprecated - TODO: Make optional in a separate ticket
} as const;

export const getSystemAggJiraIssueType = (fields?: AggFieldConnectionEdges): AggJiraIssueType => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const issueTypeField = (fields as AggFieldConnectionEdge[])?.find(
		(edge) =>
			edge?.node != null &&
			edge.node.__typename === ISSUE_TYPE_GQL_FIELD &&
			edge.node.type === ISSUE_TYPE,
	)?.node;

	if (
		issueTypeField == null ||
		issueTypeField.__typename !== ISSUE_TYPE_GQL_FIELD ||
		issueTypeField.type !== ISSUE_TYPE
	) {
		throw new Error(
			`Failed to get system issue type: ${ISSUE_TYPE_GQL_FIELD} with ${ISSUE_TYPE} 'fieldId' and 'type' not found [${JSON.stringify(
				fields ?? '',
			)}]`,
		);
	}

	if (issueTypeField.issueType == null) {
		throw new Error(
			`Failed to get system issue type: ${ISSUE_TYPE_GQL_FIELD}.issueType is missing [${JSON.stringify(
				issueTypeField,
			)}]`,
		);
	}

	return issueTypeField.issueType;
};

export const transformJiraIssueTypeToLegacyIssueType = (
	issueType: AggJiraIssueType,
): IssueType => ({
	...deprecatedAttributes,
	id: issueType.issueTypeId,
	iconUrl: issueType.avatar?.medium ?? '', // Legacy uses `medium` size
	name: issueType.name,
	description:
		'description' in issueType && issueType.description != null ? issueType.description : '',
	hierarchyLevel: issueType.hierarchy.level,
	subtask: issueType.hierarchy.level === ISSUE_TYPE_HIERARCHY_LEVEL.SUBTASK_LEVEL,
});

export const transformAggIssueTypeToLegacyField: AggTransformerFunction<
	typeof ISSUE_TYPE_GQL_FIELD
> = (node) => {
	if (node.type !== ISSUE_TYPE || node.issueType == null) {
		throw new Error(
			`Failed to transform ${ISSUE_TYPE_GQL_FIELD}: type is not ${ISSUE_TYPE} or 'issueType' is empty [${JSON.stringify(
				node,
			)}]`,
		);
	}

	return {
		...transformAggNodeToLegacyField(node),
		value: transformJiraIssueTypeToLegacyIssueType(node.issueType),
		schema: {
			configuration: null,
			custom: null,
			items: null,
			renderer: PLAIN_TEXT,
			system: ISSUE_TYPE,
			type: ISSUE_TYPE,
		},
	};
};
