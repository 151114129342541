import type { ComponentType } from 'react';
import type { HorizontalPositioning } from '@atlassian/jira-virtual-table/src/model/callbacks/index.tsx';
import { setHorizontalOffsetAction, updateVerticalOffsetAction } from '../state/actions/offsets';
import type { State } from '../state/reducers/types';
import { getColumns } from '../state/selectors/columns';
import { mergeIssueKeysWithLockedIssues } from '../state/selectors/issues';
import { getHorizontalScrollOffset, getVerticalScrollOffset } from '../state/selectors/offsets';
import {
	shouldShowPagination,
	getEditingFieldHorizontalOffset,
	getEditingFieldVerticalOffset,
	getEditingFieldWidth,
	getTableWidth,
	getTableHeight,
} from '../state/selectors/ui';
import { connect } from '../state/store';
import type { PropsWithDefaults } from './common';
import Pagination from './pagination';
import Table from './table';
import IssueTable from './view';

const mapStateToProps = (state: State) => ({
	Table,
	columns: getColumns(state),
	Pagination,
	showPagination: shouldShowPagination(state),
	editingFieldHorizontalOffset: getEditingFieldHorizontalOffset(state),
	editingFieldVerticalOffset: getEditingFieldVerticalOffset(state),
	editingFieldWidth: getEditingFieldWidth(state),
	horizontalScrollOffset: getHorizontalScrollOffset(state),
	verticalScrollOffset: getVerticalScrollOffset(state),
	tableWidth: getTableWidth(state),
	tableHeight: getTableHeight(state),
	loadedIssueKeys: mergeIssueKeysWithLockedIssues(state),
});

const mapDispatchToProps = {
	onUpdateHorizontalScrollOffset: (offset: HorizontalPositioning) =>
		setHorizontalOffsetAction(offset.offsetFromLeft),
	onUpdateVerticalScrollOffset: updateVerticalOffsetAction,
} as const;

// @ts-expect-error - TS2322 - Type 'ComponentClass<Omit<DispatchProp<any>, "dispatch">> & { WrappedComponent: Component<DispatchProp<any>>; }' is not assignable to type 'ComponentType<PropsWithDefaults>'. | TS2554 - Expected 3-4 arguments, but got 2.
const ConnectedIssueTable: ComponentType<PropsWithDefaults> = connect(
	mapStateToProps,
	mapDispatchToProps,
)(IssueTable);

export default ConnectedIssueTable;
