import includes from 'lodash/includes';
import memoizeOne from 'memoize-one';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	RowConfiguration,
	RowsConfiguration,
} from '@atlassian/jira-virtual-table/src/model/rows';

const LOADING_ROW_CONFIGURATION: RowConfiguration = {
	isLoading: true,
};

export default memoizeOne(
	(
		issueKeys: string[],
		selectedIssueKeys: IssueKey[],
	): {
		rowIds: string[];
		rowsConfiguration: RowsConfiguration;
	} => {
		const rowIds: string[] = [];
		const rowsConfiguration: RowsConfiguration = {};
		for (let i = 0; i < issueKeys.length; i += 1) {
			const issueKey: string | undefined = issueKeys[i];
			if (issueKey == null) {
				rowIds[i] = i.toString();
				rowsConfiguration[i.toString()] = LOADING_ROW_CONFIGURATION;
			} else {
				rowIds[i] = issueKey;
				if (includes(selectedIssueKeys, issueKey)) {
					rowsConfiguration[issueKey] = { isSelected: true };
				}
			}
		}
		return { rowIds, rowsConfiguration };
	},
);
