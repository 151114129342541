import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types';
import type { RouteResourceError } from '@atlassian/react-resource-router';
import type { State } from '../../reducers/types';

export const getSsrIssueList = (state: State): IssueListResponse | undefined =>
	state.persisted.ssrIssuesData?.ssrIssueList;

export const getIsInitialLoad = (state: State): boolean | undefined =>
	state.persisted.ssrIssuesData?.isInitialLoading;

export const getSsrIssuesError = (state: State): RouteResourceError | undefined =>
	state.persisted.ssrIssuesData?.error;
