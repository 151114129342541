import React, { type FC } from 'react';
import { IconTile } from '@atlaskit/icon';
import OfficeBuildingIcon from '@atlaskit/icon/core/migration/office-building--office-building-filled';
import TagIcon from '@atlaskit/icon/core/migration/tag--label';
import type { GlyphProps } from '@atlaskit/icon/types';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';

type LabelIcon = {
	size: 'large' | 'small' | 'xsmall';
	type: 'ORGANIZATION' | 'PRODUCT';
	label?: string;
} & Omit<GlyphProps, 'size' | 'label'>;

export const LabelIcon: FC<LabelIcon> = ({ size, type, label = '', testId, ...etc }) => {
	const Icon = type === 'ORGANIZATION' ? OfficeBuildingIcon : TagIcon;
	const iconSize = size === 'large' ? ('large' as const) : ('small' as const);
	const isLarge = size === 'large';

	if (isVisualRefreshEnabled()) {
		return (
			<Box
				xcss={[containerStyles, size === 'small' && smallStyles, size === 'xsmall' && xsmallStyles]}
				testId={testId}
			>
				{isLarge ? (
					<IconTile icon={Icon} appearance="gray" shape="square" size="40" label={label} />
				) : (
					<Icon color={token('color.icon', colors.N500)} label={label} {...etc} />
				)}
			</Box>
		);
	}

	return (
		<Box
			xcss={[
				containerStyles,
				size === 'large' && largeStyles,
				size === 'small' && smallStyles,
				size === 'xsmall' && xsmallStyles,
			]}
			testId={testId}
		>
			<Icon
				LEGACY_primaryColor={token('color.icon', colors.N500)}
				LEGACY_size={iconSize}
				label={label}
				{...etc}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	display: 'flex',
	backgroundColor: 'color.background.neutral',
});

const largeStyles = xcss({
	borderRadius: 'border.radius.200',
	padding: 'space.050',
});

const smallStyles = xcss({
	borderRadius: 'border.radius.050',
	padding: 'space.050',
});

const xsmallStyles = xcss({
	borderRadius: 'border.radius.050',
	padding: 'space.025',
});
