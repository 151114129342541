import {
	type FORGE_USER_GQL_FIELD,
	ISSUE_FIELD_USER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';
import isFieldMissingRenderer from './helpers/is-field-missing-renderer.tsx';
import { transformJiraUserToLegacyServerUser } from './user-node-transformer';

export const transformAggForgeUserToLegacyField: AggTransformerFunction<
	typeof FORGE_USER_GQL_FIELD
> = (node) => {
	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	return {
		...transformAggNodeToLegacyField(node),
		value: node.user ? transformJiraUserToLegacyServerUser(node.user) : null,
		autoCompleteUrl: node.searchUrl,
		schema: {
			configuration: null,
			items: null,
			system: null,
			custom: node.type,
			renderer: node.renderer,
			type: ISSUE_FIELD_USER_CF_TYPE,
		},
	};
};
