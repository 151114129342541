import {
	type PROFORMA_FORMS_GQL_FIELD,
	PROFORMA_FORMS,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggProformaFormsToLegacyField: AggTransformerFunction<
	typeof PROFORMA_FORMS_GQL_FIELD
> = (node) => {
	if (node.type !== PROFORMA_FORMS) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: node.proformaForms,
		schema: {
			configuration: null,
			items: null,
			system: PROFORMA_FORMS,
			custom: null,
			renderer: null,
			type: PROFORMA_FORMS,
		},
	};
};
