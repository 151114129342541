import { useMemo } from 'react';
import type { ProjectSidebarAddonNavItem } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-connect-addons-navigation/types.tsx';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/types.tsx';
import type { UseResourceReturn } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import type { RouteResource } from '@atlassian/react-resource-router';
import { transformLinkV4 } from '../../common/utils/transform-link';

export const useConnectAppNavigationData = (
	projectKey: string,
	resource: RouteResource<ProjectSidebarAddonNavItem[] | null>,
): UseResourceReturn<ConnectDataItem[] | null> => {
	const { data, ...rest } = useResourceWithCustomRouterContext<ProjectSidebarAddonNavItem[] | null>(
		resource,
		{
			matchParams: { projectKey },
		},
	);

	const connectDataItems = useMemo(
		() => data?.map(transformLinkV4)?.filter(Boolean) || null,
		[data],
	);

	return {
		data: connectDataItems,
		...rest,
	};
};
