import { merge } from 'icepick';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { TRACE_ID_HEADER } from './constants';
import FetchError from './errors';
import { getReroutableURL } from './get-reroutable-url';
import { makeObservabilityHeaders } from './observability-headers';

const noContentStatus = 204;

const transformUrl = (url: string) => {
	const lUrl = url.replace(/\//g, '_');
	const resUrl = lUrl.substr(0, lUrl.indexOf('?') > -1 ? lUrl.indexOf('?') : lUrl.length);
	return resUrl;
};

export type JiraFetchOptions = Readonly<RequestInit> & {
	readonly perf?: {
		readonly prefix: undefined | string;
		readonly key: string;
	};
	readonly headersProcessor?: (arg1: Response['headers']) => void;
};

// This function returns a JSON stream which emits a single JSON value on success, and throws
// an error on failure
// Pass the perf object in the options in order to enable performance analytics for your request
// fetchJson$ = ('some url', { perf: { key: 'analytics_key', prefix: 'analytics_prefix' } })
// Pass the headersProcessor in the options to provide a callback function which can respond on headers.
function fetchJson$<T = unknown>(url: string, options: JiraFetchOptions = {}): Observable<T> {
	const { perf, headersProcessor, ...opts } = options;
	let key: string;
	const isPerformanceCheckEnabled = !!perf;
	if (isPerformanceCheckEnabled) {
		const mark = `REST${transformUrl(url)}`;
		key =
			(perf &&
				`${perf.prefix != null && perf.prefix !== '' ? `${perf.prefix}_` : ''}${perf.key}`) ||
			mark;
		setMark(`BEGIN_${key}`);
	}

	return Observable.of(url)
		.mergeMap(() =>
			fetch(
				getReroutableURL(url),
				merge(merge(defaultOptions, opts), makeObservabilityHeaders(url)),
			),
		)
		.mergeMap((response) => {
			if (isPerformanceCheckEnabled) {
				setMark(`END_${key}`);
			}

			if (!response.ok) {
				const { status } = response;
				return response.text().then((str) => {
					const traceId = response.headers.get(TRACE_ID_HEADER);
					if (traceId != null && traceId !== '') {
						throw new FetchError(status, str, traceId);
					} else {
						throw new FetchError(status, str);
					}
				});
			}

			if (typeof headersProcessor === 'function') {
				headersProcessor(response.headers);
			}

			if (response.status === noContentStatus) {
				// We want it to return a `null` value.
				// Using `Observable.empty<never>()` would just hang as `mergeMap`
				// will be waiting for values that would never come.
				return Observable.of(null);
			}

			return response.json();
		});
}

export default fetchJson$;
