import React, { type ComponentType } from 'react';
import noop from 'lodash/noop';
import { lazyForPaint } from 'react-loosely-lazy';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import type { AnalyticsSource as PageAnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import type { IssueNavigatorData } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async.tsx';
import type { IssueAppProps } from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';
import { getIssueContainerMaxWidth } from '@atlassian/jira-issue-view/src/views/issue-details/issue-layout/constants';
import { useEcClient } from '@atlassian/jira-jsis-ec-client/src/services/ec-components/index.tsx';
import { useAnalyticsEvents, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { ServiceDeskAnalyticsContext as ServiceDeskAnalyticsContextDI } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import { saveIssueMutationAnalyticsToCache } from '@atlassian/jira-servicedesk-common/src/issue-change';
import ServicedeskIssueContainer from '@atlassian/jira-servicedesk-queues-issue-container/src/view/view';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';

const isEcClientEnabled = () => {
	const experiences: string[] = getFeatureFlagValue<string>(
		'hela.ec.client.integration.jsm',
		'',
	).split(',');
	return Array.isArray(experiences) && experiences.includes('jsm-issue-view');
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyIssueApp: ComponentType<IssueAppProps> = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);

export type Props = {
	backButtonUrl?: string;
	issueNavigatorData?: IssueNavigatorData;
	analyticsSource: PageAnalyticsSource;
	issueKey: IssueKey;
	onDispatchExternalActionRef: () => void;
	onIssueDeleteSuccess: () => void;
	ServiceDeskAnalyticsContext?: typeof ServiceDeskAnalyticsContextDI;
};

type OnIssueKeyChangeArgument = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

/**
 * NOTE: This component is used **only for issues navigated from a JSM queue**, not for any JSM issue. See this package's Readme.md for more info
 */
export const IssueView = ({
	backButtonUrl,
	issueNavigatorData,
	issueKey,
	onDispatchExternalActionRef,
	onIssueDeleteSuccess,
	analyticsSource,
	ServiceDeskAnalyticsContext = ServiceDeskAnalyticsContextDI,
}: Props) => {
	const routerActions = useRouterActions();
	const [selectedIssueKey, setIssueKey] = useQueryParam('selectedIssue');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let saveIssueMutationToCache: any;
	if (isEcClientEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		saveIssueMutationToCache = useEcClient().saveIssueMutationToCache;
	}
	const onIssueKeyChange = (data: OnIssueKeyChangeArgument) => {
		setIssueKey(data.toIssueKey);

		if (ff('jsm-redirecting-issue-to-browse-url-when-old-key-is-invalid')) {
			logger.safeInfoWithoutCustomerData(
				'jsm.issue.view.issue-key-change',
				'Invalid issue key, redirecting to correct issue key',
			);
			routerActions.push(`/browse/${data.toIssueKey}`);
		}
	};

	const onChangeHandler = (event: ChangeEvent) => {
		saveIssueMutationAnalyticsToCache(event, createAnalyticsEvent, saveIssueMutationToCache);
	};

	return (
		<ServicedeskIssueContainer>
			<ServiceDeskAnalyticsContext>
				<IssueBoundary packageName="servicedesk-issue-view">
					<LazyIssueApp
						onClose={noop}
						analyticsSource={analyticsSource}
						backButtonUrl={backButtonUrl}
						dispatchExternalActionRef={onDispatchExternalActionRef}
						shouldSetInitialFocus
						isLoadedWithPage
						issueKey={toIssueKey(selectedIssueKey) || issueKey}
						onIssueDeleteSuccess={onIssueDeleteSuccess}
						onIssueKeyChange={onIssueKeyChange}
						issueMaxWidth={getIssueContainerMaxWidth()}
						onChange={onChangeHandler}
						issueNavigatorData={issueNavigatorData}
					/>
				</IssueBoundary>
			</ServiceDeskAnalyticsContext>
		</ServicedeskIssueContainer>
	);
};

const getAnalyticsSourceFromUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { pathname } = window.location;
	if (pathname.includes('reports')) {
		return 'viewReportIssue';
	}
	return 'viewQueueIssue';
};

const WrappedIssueView = AnalyticsSource(getAnalyticsSourceFromUrl(), SCREEN)(IssueView);

const IssueViewContainer = (props: Props) => <WrappedIssueView {...props} />;

export default IssueViewContainer;
