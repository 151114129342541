import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types';
import { RICH_CONTENT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	customFieldsToDisplay,
	ecosystemCustomFields,
	specialFields,
} from '@atlassian/jira-issue-view-configurations';
import {
	DESCRIPTION_TYPE,
	FLAGGED_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
} from '@atlassian/jira-platform-field-config';

const atlassianSystemCFTypes = (): string[] => {
	const customSystemFields = specialFields();
	return [
		...customFieldsToDisplay().filter((cf) => !ecosystemCustomFields.includes(cf)),
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'SpecialFieldTypes'.
		...Object.keys(customSystemFields).map((key) => customSystemFields[key]),
		FLAGGED_CF_TYPE,
	];
};

export const getAtlassianCustomFieldType = (custom: string | null): string | undefined =>
	atlassianSystemCFTypes().find((type) => type === custom);

export const getFieldType = (
	field?: Field | FieldConfiguration<unknown> | null,
): string | undefined => {
	const fieldSchema = field ? field.schema : undefined;
	return fieldSchema
		? getAtlassianCustomFieldType(fieldSchema.custom) || fieldSchema.system || fieldSchema.type
		: undefined;
};

export const isRichTextSupported = (
	field?: Field | FieldConfiguration<unknown> | null,
): boolean => {
	const fieldSchema = field ? field.schema : undefined;
	return fieldSchema
		? (fieldSchema.type === ISSUE_FIELD_STRING_CF_TYPE && fieldSchema.renderer === RICH_CONTENT) ||
				getFieldType(field) === DESCRIPTION_TYPE
		: false;
};
