import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import uniqWith from 'lodash/uniqWith';
import { fireOperationalAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-operational-event.tsx';
import { connect } from '../../../../../../../common/table-redux';
import type { RowAnalyticsData } from '../../../../../../../model/rows';
import { getVisibleCoreColumnIds } from '../../../../../../../state/consumer/selectors/columns/index.tsx';
import {
	getVisibleAdditionalColumnIds,
	isAutoHeight,
} from '../../../../../../../state/consumer/selectors/index.tsx';
// eslint-disable-next-line import/order
import {
	getRowTree,
	getExpandedRowIdsHash,
	getAddBarOverflow,
	getTemporaryAddedRow,
	getAbsoluteRowHeight,
	getRowHeightMapping,
} from '../../../../../../../state/consumer/selectors/rows/index.tsx';

import {
	isAddLinkShown,
	canLastRowAddSiblingOrChildren,
	getShouldVirtualize,
} from '../../../../../../../state/selectors';
import type { State } from '../../../../../../../state/types';
import RowList from './index-dumb';
import RowItem from './row-item';

const ASSIGNEE_COLUMN = 'assignee';

type GroupedByDisplayBoundaryData = {
	minDisplayStart: number;
	maxDisplayEnd: number;
	groups: {
		[key: number]: {
			startTime: number;
			endTime: number;
			meanMountTime: number;
		};
	};
};

type StatsData = {
	median: number;
	perc90: number;
	perc95: number;
	max: number;
};

export const groupDataByDisplayBoundary = (
	analyticsDataByColumns: RowAnalyticsData[],
): GroupedByDisplayBoundaryData => {
	const groupedData = {
		minDisplayStart: Number.MAX_SAFE_INTEGER,
		maxDisplayEnd: 0,
		groups: {},
	};

	analyticsDataByColumns.forEach((analyticsData) => {
		const { mountBeginTime, mountEndTime, displayStart, displayEnd } = analyticsData;
		const batchSize = analyticsDataByColumns.filter((v) => v.displayStart === displayStart).length;

		groupedData.minDisplayStart = Math.min(displayStart, groupedData.minDisplayStart);
		groupedData.maxDisplayEnd = Math.max(displayEnd, groupedData.maxDisplayEnd);

		// group each data by the display boundary and calculate the avg time for each group
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
		if (!groupedData.groups[displayStart]) {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
			groupedData.groups[displayStart] = {
				startTime: mountBeginTime,
				endTime: mountEndTime,
				meanMountTime: (mountEndTime - mountBeginTime) / batchSize,
			};
		} else {
			// Always take the earliest begin and end times
			const newStartTime = Math.min(
				mountBeginTime,
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
				groupedData.groups[displayStart].startTime,
			);
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
			const newEndTime = Math.min(mountEndTime, groupedData.groups[displayStart].endTime);

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
			groupedData.groups[displayStart].startTime = newStartTime;
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
			groupedData.groups[displayStart].endTime = newEndTime;
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
			groupedData.groups[displayStart].meanMountTime = (newEndTime - newStartTime) / batchSize;
		}
	});

	return groupedData;
};

export const getStats = (groupedData: GroupedByDisplayBoundaryData): StatsData => {
	const sortedMountTimes = Object.values(groupedData.groups)
		.map((v) => v.meanMountTime)
		.sort((a, b) => a - b);
	const dataLength = sortedMountTimes.length;

	return {
		median: Math.round(sortedMountTimes[Math.floor(dataLength / 2)]),
		perc90: Math.round(sortedMountTimes[Math.floor(dataLength * 0.9)]),
		perc95: Math.round(sortedMountTimes[Math.floor(dataLength * 0.95)]),
		max: Math.round(sortedMountTimes[dataLength - 1]),
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fireRowMountAnalytics = (accumulatedRowAnalyticsData: any) => {
	// if the user added/removed columns or switched to a different list that has different number of columns
	// fire the analytics separately for each of them
	const uniqueColumns = uniqWith(
		// @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
		accumulatedRowAnalyticsData.map((v) => v.columns),
		isEqual,
	);

	// Get the metrics for each batch grouped by no. of columns
	uniqueColumns.forEach((columnArray) => {
		// @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
		const analyticsDataByColumns = accumulatedRowAnalyticsData.filter((v) =>
			isEqual(v.columns, columnArray),
		);
		const { columns, numberOfColumns, numberOfRows, analyticsEvent } = analyticsDataByColumns[0];

		const hasNewAssigneeField = columns.includes(ASSIGNEE_COLUMN);

		const groupedData = groupDataByDisplayBoundary(analyticsDataByColumns);

		const { median, perc90, perc95, max } = getStats(groupedData);

		fireOperationalAnalytics(analyticsEvent, {
			containerType: 'virtualTable',
			attributes: {
				totalNoOfRowsInTable: numberOfRows,
				columns,
				numberOfColumns,
				numberOfRowsInEvent: analyticsDataByColumns.length,
				medianTimeTakenToMount: median,
				perc90TimeTakenToMount: perc90,
				perc95TimeTakenToMount: perc95,
				maxTimeTakenToMount: max,
				minDisplayStart: groupedData.minDisplayStart,
				maxDisplayEnd: groupedData.maxDisplayEnd,
				hasNewAssigneeField,
			},
		});
	});
};

const getVisibleCoreColumnIdsMemoized = () =>
	createSelector(
		(state: State) =>
			[].concat(
				// @ts-expect-error - TS2769 - No overload matches this call.
				getVisibleCoreColumnIds(state),
				getVisibleAdditionalColumnIds(state),
			),
		(res) => res,
	);
const mapStateToPropsFactory = () => {
	const getVisibleCoreColumnIdsInstance = getVisibleCoreColumnIdsMemoized();
	return (state: State) => {
		const hasAutoHeight = isAutoHeight(state);

		const onRowContentMount =
			// only when table is virtualised
			!hasAutoHeight && getShouldVirtualize(state) ? fireRowMountAnalytics : noop;

		return {
			rowTree: getRowTree(state),
			expandedRowIdsHash: getExpandedRowIdsHash(state),
			addBarOverflow: getAddBarOverflow(state),
			temporaryAddedRow: getTemporaryAddedRow(state),
			rowHeightMapping: getRowHeightMapping(state),
			absoluteRowHeight: getAbsoluteRowHeight(state),
			lastRowHasAddBar: canLastRowAddSiblingOrChildren(state),
			addLinkShown: isAddLinkShown(state),
			visibleColumns: getVisibleCoreColumnIdsInstance(state),
			onRowContentMount,
			RowItem,
		};
	};
};

export default connect(mapStateToPropsFactory, {})(RowList);
