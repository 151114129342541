import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import { getFieldTypesEligibleForJsonData } from '@atlassian/jira-servicedesk-queues-common/src/json-fields';
import { LOAD_ISSUES_ACTION_SOURCE_PAGE } from '../../../model';
import { maybeIssuesInLocalStorage } from '../../../services/issue/local-storage';
import { createSortedQueue } from '../../../services/sort';
import { experienceTrackingResetAction } from '../../../state/actions/experience-tracking';
import { filterQueryChangedAction } from '../../../state/actions/filter';
import { loadIssuesAction, loadIssuesSuccessAction } from '../../../state/actions/issue';
import { reloadPageDataAction } from '../../../state/actions/page';
import { QUEUE_CHANGED, setQueueAction } from '../../../state/actions/queue';
import { pageChangedAction } from '../../../state/actions/table';
import type { Action } from '../../../state/actions/types';
import type { State } from '../../../state/reducers/types';
import { getCreateAnalyticsEvent } from '../../../state/selectors/analytic';
import { isQueueMateriallyDifferent } from '../../../state/selectors/queue';
import { hasPageError } from '../../../state/selectors/ui';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(QUEUE_CHANGED).switchMap((action) => {
		const state = store.getState();
		const { queue } = action.payload;

		let createAnalyticsEvent;

		if (fg('view_queues_local_storage_data_updated')) {
			createAnalyticsEvent = getCreateAnalyticsEvent(state);
		}

		const sortedQueue = createSortedQueue(queue, createAnalyticsEvent);

		const queueId = sortedQueue.id;

		if (hasPageError(state)) {
			return Observable.of(reloadPageDataAction(queueId));
		}

		if (!isQueueMateriallyDifferent(state, sortedQueue)) {
			return Observable.of(setQueueAction(sortedQueue));
		}

		const columnTypesAsJson = getFieldTypesEligibleForJsonData();
		const { columns, jql, manualSorting } = sortedQueue;

		const actions = [
			experienceTrackingResetAction(), // Need to reset experience tracking before/with changing the queueId
			...[filterQueryChangedAction(null)],
			setQueueAction(sortedQueue),
			pageChangedAction(1),
			loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_PAGE, true),
		];

		const sortedBy = manualSorting && manualSorting.sortedBy;
		const sortOrder = manualSorting && manualSorting.sortOrder;

		return maybeIssuesInLocalStorage(
			queueId,
			jql,
			columns,
			columnTypesAsJson,
			null,
			sortedBy,
			sortOrder,
		).cata(
			() => Observable.of(batchActions(actions)),
			// @ts-expect-error - TS7006 - Parameter 'issues' implicitly has an 'any' type.
			(issues) =>
				Observable.of(
					batchActions([
						...actions,
						loadIssuesSuccessAction(issues, queueId, 0, LOAD_ISSUES_ACTION_SOURCE_PAGE),
					]),
				),
		);
	});
