import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import EmptyState from '@atlaskit/empty-state';
import { fg } from '@atlassian/jira-feature-gating';
import ErrorIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error-white';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import errorImage from '../../../common/assets/error-subtle.svg';
import messages from './messages';

type Props = {
	onFilterClear: () => void;
};

const FilterQueryFailed = ({ onFilterClear }: Props) => {
	const { formatMessage } = useIntl();
	const renderImage = () =>
		getAkEmptyStateRenderImageFn(ErrorIllustration)({
			imageWidth: 120,
			maxImageWidth: 120,
			maxImageHeight: 160,
		});
	return (
		<Wrapper>
			<EmptyState
				testId="servicedesk-queues-agent-view.layout.filter-query-failed.query-failed"
				imageUrl={fg('jsm_queues_consolidate_jfe_assets') ? undefined : errorImage}
				renderImage={fg('jsm_queues_consolidate_jfe_assets') ? renderImage : undefined}
				header={formatMessage(messages.errorHeader)}
				description={formatMessage(messages.errorMessage)}
				primaryAction={
					<Button
						appearance="link"
						onClick={onFilterClear}
						testId="servicedesk-queues-agent-view.layout.filter-query-failed.clear-search-button"
					>
						{formatMessage(messages.clearSearch)}
					</Button>
				}
			/>
		</Wrapper>
	);
};

export { FilterQueryFailed };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});
