import flow from 'lodash/flow';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { HeaderComponentProps } from '@atlassian/jira-virtual-table/src/model/columns';
import type { SortOrder } from '../../model/fields/sort-order';
import type { State } from '../../state/reducers/types';
import {
	maybeColumnTitle,
	getCurrentSorting,
	getFieldType,
	getOnSortOrderChanged,
	isDisabled,
} from '../../state/selectors/columns';
import { connect } from '../../state/store';
import { ReduxAnalyticsData } from '../analytics';
import SortColumnHeader from './view';

const mapStateToProps = (state: State, ownProps: HeaderComponentProps) => {
	const { columnId } = ownProps;
	const onSortOrderChange = getOnSortOrderChanged(state, columnId);
	const sortOrder = getCurrentSorting(state, columnId);
	let isSortable = false;
	if (onSortOrderChange) {
		isSortable = true;
	}
	return {
		columnName: maybeColumnTitle(state, columnId).orSome(''),
		fieldType: getFieldType(state, columnId),
		sortOrder,
		isDisabled: isDisabled(state, columnId) && sortOrder,
		onSortOrderChange,
		isSortable,
	};
};

export default flow(
	withFireUiAnalytics({
		onSortOrderChange: (fieldId: string, sortOrder: SortOrder) => ({
			name: 'sortOrderChange',
			fieldId,
			sortOrder,
		}),
	}),
	ReduxAnalyticsData((state: State, ownProps: HeaderComponentProps) => ({
		attributes: {
			fieldId: getFieldType(state, ownProps.columnId),
		},
	})),
	// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 1.
	connect(mapStateToProps),
)(SortColumnHeader);
