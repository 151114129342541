import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type {
	ServerRestStatus,
	StatusDeprecated,
} from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import {
	type STATUS_GQL_FIELD,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggJiraStatus } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

// Only used here
type StatusesDeprecated = {
	[key: string]: StatusDeprecated;
};

export const getStatusesDeprecatedValue = (fields: FieldsState): StatusesDeprecated => {
	const { status } = fields;

	if (!status || status.value == null) return {};

	const { value } = status;

	return {
		[value.id]: {
			id: value.id,
			name: value.name,
			statusCategory: statusCategoryForId(value.statusCategory?.id),
		},
	};
};

const transformJiraStatusToLegacyStatus = (
	status?: AggJiraStatus | null,
): ServerRestStatus | null => {
	if (status == null) return null;

	const { statusCategory } = status;

	return {
		id: status.statusId,
		name: status.name || '',
		statusCategory: {
			id: Number(statusCategory.statusCategoryId),
		},
	};
};

export const transformAggStatusToLegacyField: AggTransformerFunction<typeof STATUS_GQL_FIELD> = (
	node,
) => {
	if (node.type !== STATUS_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: transformJiraStatusToLegacyStatus(node.status),
		schema: {
			configuration: null,
			items: null,
			renderer: null,
			type: STATUS_TYPE,
			custom: null,
			system: STATUS_TYPE,
		},
	};
};
