import type { SsrIssuesState } from '../../../../common/types';

const UNSET_IS_INITIAL_LOAD = 'state.actions.page.UNSET_IS_INITIAL_LOAD' as const;

type UnsetIsInitialLoad = {
	type: typeof UNSET_IS_INITIAL_LOAD;
};

export const unsetIsInitialLoad = (): UnsetIsInitialLoad => ({
	type: UNSET_IS_INITIAL_LOAD,
});

export type Actions = UnsetIsInitialLoad;

export const reducer = (state: SsrIssuesState | null = null, action: Actions) => {
	switch (action.type) {
		case UNSET_IS_INITIAL_LOAD: {
			return {
				...state,
				isInitialLoading: false,
			};
		}

		default:
			return state || null;
	}
};

export default reducer;
