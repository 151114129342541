import React, { useCallback, useEffect } from 'react';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import BadgeDI from '@atlaskit/badge';
import QueuesIcon from '@atlaskit/icon/core/pages';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import type { MatcherLocation } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/types.tsx';
import { formatDistanceStrictWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { MenuLinkItem } from '@atlassian/navigation-system';
import { usePathParam, useRouter } from '@atlassian/react-resource-router';
import { isValidCategorisedViewQueueUrl } from '../../../../../utils/common';
import { useSelectedNavLocation } from '../../../../controllers/nav-location';
import { usePoppedOutState } from '../../../../controllers/queues-category-state/index.tsx';
import { useIsQueueSelected } from '../../common';
import IssueCountErrorMessage from './issue-count-error-message/main.tsx';
import { tooltipMessages } from './messages';
import { StarButton } from './star-button';
import type { QueueItemProps } from './types';

const MAX_BADGE_COUNT = 999;

export const matchUrlForSelectedQueueNew =
	(
		pathCategorySelectedSection: QueueListSection | undefined,
		currentQueueInSection: QueueListSection,
	) =>
	(currentLocation: MatcherLocation, itemLocation: MatcherLocation) => {
		const pathNameMatches =
			currentLocation.pathname === itemLocation.pathname ||
			currentLocation.pathname.substring(0, currentLocation.pathname.lastIndexOf('/')) ===
				itemLocation.pathname;

		const isSectionMatched = pathCategorySelectedSection === currentQueueInSection;

		return pathNameMatches && isSectionMatched;
	};

const QueueItemLegacy = ({ Badge = BadgeDI, queue, section, ...rest }: QueueItemProps) => {
	const [{ route, location }] = useRouter();
	const [queueId] = usePathParam('queueId');
	const [practice] = usePathParam('practiceType');
	const pathCategory = toItsmPractice(practice);
	const href = `/jira/servicedesk${queue.url}`;
	const isSelected = route != null && queueId === queue.id && location.pathname.startsWith(href);
	const queueCategory = queue.category || NO_CATEGORY;

	const { formatMessage } = useIntl();
	const locale = useLocale();

	const getFormattedRelativeLastRefreshedTime = useCallback(() => {
		if (queue.badgeCount === undefined) {
			return formatMessage(tooltipMessages.loadingIssueCountTooltip);
		}
		const lastRefreshedTime =
			queue.lastRefreshedTime === 0 || queue.lastRefreshedTime === undefined
				? Date.now()
				: queue.lastRefreshedTime;
		return formatMessage(tooltipMessages.showLastRefreshedTimeTooltip, {
			lastUpdate: formatDistanceStrictWithLocale(lastRefreshedTime, Date.now(), locale, true),
		});
	}, [formatMessage, locale, queue.badgeCount, queue.lastRefreshedTime]);

	const shouldShowBadge = useCallback(
		() => isSelected || queue.starred || !queue.canBeHidden,
		[isSelected, queue.canBeHidden, queue.starred],
	);

	const getDisplayBadgeValue = () => {
		const shouldDisplayCountBadge = queueId && pathCategory === queueCategory;
		if (shouldDisplayCountBadge) {
			return isNumber(queue.badgeCount) ? toNumber(queue.badgeCount) : '-';
		}
		return null;
	};

	const issueCountBadge = shouldShowBadge() && (
		<Tooltip content={() => getFormattedRelativeLastRefreshedTime()}>
			{(tooltipProps) => (
				<div data-is-queue-issue-count-badge {...tooltipProps}>
					<Badge max={MAX_BADGE_COUNT}>{getDisplayBadgeValue()}</Badge>
				</div>
			)}
		</Tooltip>
	);
	const starButton = (
		<>
			<StarButton isStarred={queue.starred} queueId={queue.id} />
		</>
	);

	const shouldShowIssueCountError = useCallback(
		() => queue.shouldSkipIssueCountRefresh && (queue.starred || !queue.canBeHidden),
		[queue.canBeHidden, queue.shouldSkipIssueCountRefresh, queue.starred],
	);

	const issueCountIcon = shouldShowIssueCountError() ? (
		<>
			<IssueCountErrorMessage queue={queue} />
		</>
	) : (
		<>{issueCountBadge}</>
	);
	return (
		<MenuLinkItem
			analytics={{
				itemId: `queue-${queue.id}`,
			}}
			href={href}
			isSelected={isSelected}
			elemAfter={issueCountIcon}
			actionsOnHover={starButton}
			elemBefore={<QueuesIcon label="" spacing="spacious" color={token('color.icon')} />}
			aria-label={queue.name}
			{...rest}
		>
			{queue.name}
		</MenuLinkItem>
	);
};

const QueueItemNew = ({
	Badge = BadgeDI,
	queue,
	section,
	projectKey,
	priorityGroupId,
	isQueueInFlyOut = false,
	...rest
}: QueueItemProps) => {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const [{ route, location }] = useRouter();
	const pathname = location.pathname;

	const [queueIdFromPath] = usePathParam('queueId');
	const [practiceFromPath] = usePathParam('practiceType');
	const [projectKeyFromPath] = usePathParam('projectKey');
	const categoryFromPath = toItsmPractice(practiceFromPath);
	const categoryFromQueue = queue.category || NO_CATEGORY;
	const [selectedNavLocation, { updateSelectedNavLocation }] = useSelectedNavLocation(
		queueIdFromPath || '',
	);
	const isViewQueueUrl = isValidCategorisedViewQueueUrl(
		projectKey || '',
		categoryFromQueue,
		queueIdFromPath,
		pathname,
	);

	// Is queue selected logic
	const href = `/jira/servicedesk${queue.url}`;
	const queueMatchesRoute =
		route != null && queueIdFromPath === queue.id && location.pathname.startsWith(href);
	const isQueueSelected = useIsQueueSelected(queue, section, priorityGroupId) && queueMatchesRoute;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isPoppedOut] = usePoppedOutState(`${projectKey}-${categoryFromQueue}`);
	useEffect(() => {
		if (isQueueSelected) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'queueItemSelected rendered', {
				id: queue.id,
				name: queue.name,
				category: categoryFromQueue,
				section,
				priorityGroupId,
				isQueueInFlyOut,
				isQueueInPopOutPanel: isPoppedOut,
			});
		}
	}, [
		categoryFromQueue,
		createAnalyticsEvent,
		isQueueInFlyOut,
		isQueueSelected,
		isPoppedOut,
		priorityGroupId,
		queue.category,
		queue.id,
		queue.name,
		section,
	]);
	// Internal methods
	const getFormattedRelativeLastRefreshedTime = useCallback(() => {
		if (queue.badgeCount === undefined) {
			return formatMessage(tooltipMessages.loadingIssueCountTooltip);
		}
		const lastRefreshedTime = queue.lastRefreshedTime || Date.now();
		return formatMessage(tooltipMessages.showLastRefreshedTimeTooltip, {
			lastUpdate: formatDistanceStrictWithLocale(lastRefreshedTime, Date.now(), locale, true),
		});
	}, [formatMessage, locale, queue.badgeCount, queue.lastRefreshedTime]);

	const onMenuItemClick = () => {
		updateSelectedNavLocation(queue.id, section, priorityGroupId);
	};

	// Should queue show issue count badge
	const inSelectedProject = projectKey === projectKeyFromPath;
	const inSelectedCategory = categoryFromQueue === categoryFromPath;
	const isValidLocation = isViewQueueUrl && inSelectedProject && inSelectedCategory;
	const shouldShowBadgeInSection: Record<QueueListSection, boolean> = {
		[QueueListSection.STARRED]: true,
		[QueueListSection.PRIORITY_GROUP]: selectedNavLocation.groupId === priorityGroupId,
		[QueueListSection.ALL_QUEUES]: isQueueSelected,
	};

	const shouldShowBadge = isValidLocation && shouldShowBadgeInSection[section];
	const badgeDisplayValue =
		shouldShowBadge && (isNumber(queue.badgeCount) ? toNumber(queue.badgeCount) : '-');
	const issueCountBadge = shouldShowBadge && (
		<Tooltip content={() => getFormattedRelativeLastRefreshedTime()}>
			<div data-is-queue-issue-count-badge>
				<Badge max={MAX_BADGE_COUNT}>{badgeDisplayValue}</Badge>
			</div>
		</Tooltip>
	);
	const starButton = <StarButton isStarred={queue.starred} queueId={queue.id} />;

	// issue count errors
	const shouldShowIssueCountError = queue.shouldSkipIssueCountRefresh && shouldShowBadge;
	const issueCountIcon = shouldShowIssueCountError ? (
		<IssueCountErrorMessage queue={queue} />
	) : (
		<>{issueCountBadge}</>
	);

	return (
		<MenuLinkItem
			analytics={{
				itemId: `queue-${queue.id}`,
			}}
			href={href}
			isSelected={isQueueSelected && !isQueueInFlyOut}
			onClick={onMenuItemClick}
			elemAfter={issueCountIcon}
			actionsOnHover={starButton}
			elemBefore={<QueuesIcon label="" spacing="spacious" color={token('color.icon')} />}
			aria-label={queue.name}
			{...rest}
		>
			{queue.name}
		</MenuLinkItem>
	);
};

export const QueueItem = componentWithFF(
	'jsm-queues-nav4-refresh-m2-dogfooding_rh43y',
	QueueItemNew,
	QueueItemLegacy,
);
