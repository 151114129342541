// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { IssueStatusFieldBoundary } from '@atlassian/jira-issue-field-status/src/ui/async.tsx';
import type IssueStatusFieldType from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import { trackUpdatedField } from '../../../../services/field/update';
import PreventKeyboardPropWrapper from '../../prevent-keyboard-propagation-wrapper/view.tsx';
import type { Props } from '../types';

const FIELD_TYPE = 'status';

type State = {
	inEditState: boolean;
};

// TODO reevaluate phase - forPaint is simply for initial parity
const IssueStatusField = lazyForPaint<typeof IssueStatusFieldType>(
	() =>
		import(/* webpackChunkName: "async-issue-field-status" */ '@atlassian/jira-issue-field-status'),
);

// eslint-disable-next-line jira/react/no-class-components
export default class EditableStatus extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { inEditState: false };
	}

	onSuccess = (fieldValue: StatusValue, viaDialog: boolean, event: UIAnalyticsEvent) => {
		const { onChange, issueKey, issueFieldLockControls } = this.props;

		trackUpdatedField(event, FIELD_TYPE, this.getDataForAnalytics(fieldValue));

		// issue-field/status type does not have a description but JSD status type expects one
		const status = {
			...fieldValue,
			description: '',
		};
		onChange && onChange(issueKey, status);
		issueFieldLockControls.onFieldUnlock();
	};

	onSubmit = () => {
		this.setState({ inEditState: false });
	};

	onEditStart = () => {
		this.props.issueFieldLockControls.onFieldLock();
		this.setState({ inEditState: true });
	};

	onEditCancel = () => {
		this.props.issueFieldLockControls.onFieldUnlock();
		this.setState({ inEditState: false });
	};

	getDataForAnalytics(fieldValue: StatusValue) {
		const { value } = this.props;
		return {
			...(value
				? {
						oldId: value.id,
						oldCategory: value.statusCategory.id,
					}
				: {}),
			...(fieldValue
				? {
						newId: fieldValue.id,
						newCategory: fieldValue.statusCategory.id,
					}
				: {}),
		};
	}

	render() {
		const {
			value,
			appearance,
			issueKey,
			issueFieldLockControls: { onFieldUnlock },
		} = this.props;

		const status: StatusValue = {
			id: value.id,
			name: value.name,
			statusCategory: value.statusCategory,
		};

		return (
			<PreventKeyboardPropWrapper inEditState={this.state.inEditState}>
				<IssueStatusFieldBoundary
					packageName="issue-table"
					fallback={<IssueStatusFieldReadonly value={status} />}
				>
					<IssueStatusField
						value={status}
						appearance={appearance}
						issueKey={issueKey}
						isOptimistic={false}
						onSubmit={this.onSubmit}
						onSuccess={this.onSuccess}
						onFailure={onFieldUnlock}
						onEditStart={this.onEditStart}
						onEditCancel={this.onEditCancel}
					/>
				</IssueStatusFieldBoundary>
			</PreventKeyboardPropWrapper>
		);
	}
}
