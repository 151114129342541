import { useEffect } from 'react';
import type { PracticeQueueRouteParams } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/types';
import type { UseResourceCustomContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { serviceDeskContextResource } from '@atlassian/jira-router-resources-service-desk-context/src/services/index.tsx';

const getServiceDeskContextResource = () => serviceDeskContextResource;

export const useServiceDeskContext = (
	projectKey?: string,
	customContext?: UseResourceCustomContext<PracticeQueueRouteParams>,
) => {
	const {
		data,
		loading,
		error,
		refresh: refreshCache,
	} = useResourceWithCustomRouterContext(getServiceDeskContextResource(), customContext);

	useEffect(() => {
		if (!__SERVER__) {
			refreshCache();
		}
	}, [projectKey, refreshCache]);

	return [{ data, loading, error }] as const;
};
