import { useMemo, type ReactElement } from 'react';
import type { UseResourceCustomContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { queuesNavigationItems } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import {
	NO_CATEGORY,
	type ItsmPractice,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { RouteResourceError } from '@atlassian/react-resource-router';
import { useCurrentCategory, useProjectKey } from './common/utils';
import {
	navDataSelector,
	queuesDataSelector,
	practiceMetadataSelector,
	practiceListSelector,
	type DataSelector,
	notFavouriteDataSelector,
	favouriteDataSelector,
	cannotBeHiddenAndNotFavouriteDataSelector,
	notFavouriteAndCanBeHiddenDataSelector,
} from './selectors';
import { useCategorizedActions } from './services/actions-provider/main.tsx';
import type {
	NavItem,
	ChildrenProps,
	Data,
	PracticeMetadata,
	QueueResourceCustomContext,
} from './types';

type Props<T> = {
	baseUrl: string;
	projectKey: string;
	category: ItsmPractice;
	children: (arg1: ChildrenProps<T>) => ReactElement;
};

const useCategorizedData = <T,>(
	category: Props<T>['category'],
	dataSelector: DataSelector<T>,
	customContext?: QueueResourceCustomContext,
): [Data<T>, boolean, RouteResourceError | null, () => void] => {
	const {
		data: allData,
		loading,
		error,
		refresh,
	} = useResourceWithCustomRouterContext(queuesNavigationItems, customContext);

	const memoizedData = useMemo(
		() => dataSelector(allData, category),
		[allData, category, dataSelector],
	);

	return [memoizedData, loading, error, refresh];
};

export const useCategorizedNavItems = (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
	customContext?: QueueResourceCustomContext,
) => {
	const [methods] = useCategorizedActions(baseUrl, projectKey, category, customContext);
	const [data, loading, error, refresh] = useCategorizedData<NavItem[]>(
		category,
		navDataSelector,
		customContext,
	);
	return [data, loading, error, methods, refresh] as const;
};

export const useCurrentCategorizedNavItems = () => {
	const projectKey = useProjectKey();
	const category = useCurrentCategory();
	const [data, loading, error, methods] = useCategorizedQueues('', projectKey, category);

	return [data, loading, error, methods] as const;
};

export const useNotFavouriteNavItems = (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
) => {
	const [methods] = useCategorizedActions(baseUrl, projectKey, category);
	const [data, loading, error] = useCategorizedData<NavItem[]>(category, notFavouriteDataSelector);

	return [data, loading, error, methods] as const;
};

export const useFavouriteNavItems = (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
	customContext?: QueueResourceCustomContext,
) => {
	const [methods] = useCategorizedActions(baseUrl, projectKey, category, customContext);
	const [data, loading, error] = useCategorizedData<NavItem[]>(
		category,
		favouriteDataSelector,
		customContext,
	);

	return [data, loading, error, methods] as const;
};

export const useCanBeHiddenNavItems = (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
	customContext?: QueueResourceCustomContext,
) => {
	const [methods] = useCategorizedActions(baseUrl, projectKey, category, customContext);
	const [data, loading, error] = useCategorizedData<NavItem[]>(
		category,
		notFavouriteAndCanBeHiddenDataSelector,
		customContext,
	);
	return [data, loading, error, methods] as const;
};

export const useAlwaysVisibleNavItems = (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
	customContext?: QueueResourceCustomContext,
) => {
	const [methods] = useCategorizedActions(baseUrl, projectKey, category, customContext);
	const [data, loading, error] = useCategorizedData<NavItem[]>(
		category,
		cannotBeHiddenAndNotFavouriteDataSelector,
		customContext,
	);

	return [data, loading, error, methods] as const;
};

export const useCategorizedQueues = (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
) => {
	const [methods] = useCategorizedActions(baseUrl, projectKey, category);
	const [data, loading, error] = useCategorizedData<Page[]>(category, queuesDataSelector);

	return [data, loading, error, methods] as const;
};

export const usePracticeMetadata = (
	category: ItsmPractice = NO_CATEGORY,
	customContext?: QueueResourceCustomContext,
) => useCategorizedData<PracticeMetadata>(category, practiceMetadataSelector, customContext);

export const usePracticeList = (customContext?: UseResourceCustomContext) =>
	useCategorizedData<ItsmPractice[]>(NO_CATEGORY, practiceListSelector, customContext);

export const CategorizedQueuesStore = ({
	baseUrl,
	projectKey,
	category,
	children,
}: Props<Page[]>) => {
	const [data, loading, error, methods] = useCategorizedQueues(baseUrl, projectKey, category);

	return children({
		data,
		loading,
		error,
		methods,
	});
};

export const CategorizedNavItemsStore = ({
	baseUrl,
	projectKey,
	category,
	children,
}: Props<NavItem[]>) => {
	const [data, loading, error, methods] = useCategorizedNavItems(baseUrl, projectKey, category);

	return children({
		data,
		loading,
		error,
		methods,
	});
};

CategorizedQueuesStore.defaultProps = {
	category: NO_CATEGORY,
};

CategorizedNavItemsStore.defaultProps = {
	category: NO_CATEGORY,
};
