import {
	toGroupId,
	type ApprovalDecisionStatus,
	type ApprovalStatus,
	type ApprovalDate,
	type ApproverPrincipals,
	type ApprovalConfiguration,
	type ServerApproverDTO,
	APPROVER_APPROVED,
	APPROVER_DECLINED,
	APPROVER_PENDING,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
import {
	type JSM_APPROVAL_GQL_FIELD,
	SERVICEDESK_APPROVAL_TYPE,
	JSM_GROUP_APPROVER_PRINCIPAL_FIELD,
	JSM_USER_APPROVER_PRINCIPAL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { JiraServiceManagementApprovalDecisionResponseType } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';

import { toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	AggJiraCompletedApprovalNode,
	AggJiraActiveApproval,
	AggJiraActiveApprovalConfiguration,
	AggJiraActiveApprovalApproverPrincipal,
	AggJiraApproversApprovalNode,
	AggJiraApprovalStatus,
} from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';

import { transformAggNodeToLegacyField, transformAggConnection } from './default-node-transformer';
import { transformJiraUserToLegacyServerUser } from './user-node-transformer';

const transformApproverPrincipalsToLegacy = (
	approverPrincipal: AggJiraActiveApprovalApproverPrincipal,
): ApproverPrincipals | undefined => {
	if (!approverPrincipal) {
		return undefined;
	}

	switch (approverPrincipal.__typename) {
		case JSM_USER_APPROVER_PRINCIPAL_FIELD:
			return {
				id: toAccountId(approverPrincipal.user.accountId),
				name: approverPrincipal.user.name,
				meta: {
					jiraRest: approverPrincipal.jiraRest,
				},
				type: 'user',
				avatarUrl: approverPrincipal.user.picture,
			};
		case JSM_GROUP_APPROVER_PRINCIPAL_FIELD:
			return {
				id: toGroupId(approverPrincipal.groupId),
				meta: {
					memberCount: approverPrincipal.memberCount,
					approvedCount: approverPrincipal.approvedCount,
				},
				name: approverPrincipal.name,
				type: 'group',
			};
		default:
			return undefined;
	}
};

const getApproverDecision = (
	decision: JiraServiceManagementApprovalDecisionResponseType,
): ApprovalDecisionStatus => {
	switch (decision) {
		case APPROVER_APPROVED:
			return APPROVER_APPROVED;
		case APPROVER_DECLINED:
			return APPROVER_DECLINED;
		case APPROVER_PENDING:
		default:
			return APPROVER_PENDING;
	}
};

const transformApproverToLegacy = (
	approvalNode: AggJiraApproversApprovalNode,
): ServerApproverDTO | undefined => {
	if (!approvalNode) {
		return undefined;
	}

	const approver = transformJiraUserToLegacyServerUser(approvalNode.approver);

	if (!approver) {
		return undefined;
	}

	return {
		approverDecision: getApproverDecision(approvalNode.approverDecision),
		approver,
	};
};

const transformConfigurationToLegacy = (
	configurations: AggJiraActiveApprovalConfiguration,
): ApprovalConfiguration => {
	// This function contains a lot of null checks and default empty values to satisfy flow because
	// the AGG type coming back is in the shape of [JiraServiceManagementApprovalConfiguration]
	// Which means technically it can be an empty array. Even though we will always only ever
	// get 1 object back.
	const { approversConfigurations = [], condition } = configurations?.[0] || {};
	const approverConfiguration = approversConfigurations[0];

	return {
		approvers: {
			type: approverConfiguration?.type ?? '',
			customFieldName: approverConfiguration?.fieldName ?? '',
			customFieldId: approverConfiguration?.fieldId ?? '',
		},
		condition: {
			type: condition?.type ?? '',
			value: condition?.value ?? '',
		},
	};
};

const transformDateToLegacy = (date: string): ApprovalDate => ({
	epochMillis: new Date(date).valueOf(),
});

const transformStatusToLegacy = (status: AggJiraApprovalStatus): ApprovalStatus => ({
	name: status.name || '',
	category: Number(status.categoryId),
});

const transformActiveApprovalToLegacy = (activeApproval: AggJiraActiveApproval) => {
	const {
		id,
		configurations,
		status,
		createdDate,
		canAnswerApproval,
		pendingApprovalCount,
		name,
		finalDecision,
		approvalState,
		approvedStatus,
	} = activeApproval;

	return {
		id,
		approvalState,
		approvedStatus,
		finalDecision: getApproverDecision(finalDecision),
		name,
		pendingApprovalCount,
		canAnswerApproval,
		approverPrincipals: transformAggConnection(
			activeApproval.approverPrincipals,
			transformApproverPrincipalsToLegacy,
		),
		approvers: transformAggConnection(activeApproval.approvers, transformApproverToLegacy),
		configuration: transformConfigurationToLegacy(configurations),
		createdDate: transformDateToLegacy(createdDate ?? ''),
		decisions: transformAggConnection(activeApproval.decisions, transformApproverToLegacy),
		excludedApprovers: transformAggConnection(
			activeApproval.excludedApprovers,
			transformJiraUserToLegacyServerUser,
		),
		status: status ? transformStatusToLegacy(status) : null,
	};
};

const transformCompletedApprovalToLegacy = (completedApproval: AggJiraCompletedApprovalNode) => {
	if (!completedApproval) {
		return undefined;
	}

	const { id, name, finalDecision, createdDate, completedDate, status } = completedApproval;

	return {
		id,
		name,
		finalDecision: getApproverDecision(finalDecision),
		createdDate: transformDateToLegacy(createdDate ?? ''),
		completedDate: transformDateToLegacy(completedDate),
		status: status ? transformStatusToLegacy(status) : null,
		approvers: transformAggConnection(completedApproval.approvers, transformApproverToLegacy),
	};
};

export const transformAggJSMApprovalsToLegacyField: AggTransformerFunction<
	typeof JSM_APPROVAL_GQL_FIELD
> = (node) => {
	if (node.type !== SERVICEDESK_APPROVAL_TYPE) return undefined;

	const activeApproval = node.activeApproval
		? { activeApproval: transformActiveApprovalToLegacy(node.activeApproval) }
		: {};

	return {
		...transformAggNodeToLegacyField(node),
		value: {
			...activeApproval,
			completedApprovals: transformAggConnection(
				node.completedApprovalsConnection,
				transformCompletedApprovalToLegacy,
			),
		},
		schema: {
			configuration: null,
			custom: null,
			items: null,
			renderer: null,
			system: SERVICEDESK_APPROVAL_TYPE,
			type: SERVICEDESK_APPROVAL_TYPE,
		},
	};
};
