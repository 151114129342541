import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type CONNECT_SINGLE_SELECT_GQL_FIELD,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	toLegacyConnectOption,
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer';

export const transformAggConnectSingleSelectToLegacyField: AggTransformerFunction<
	typeof CONNECT_SINGLE_SELECT_GQL_FIELD
> = (node) => {
	let value = null;

	if (node.fieldOption != null) {
		value = {
			id: parseInt(node.fieldOption?.optionId, 10),
			value: node.fieldOption?.value,
		};
	}

	return {
		...transformAggNodeToLegacyField(node),
		value,
		allowedValues: transformAggConnection(
			node.connectSingleSelectFieldOptions,
			toLegacyConnectOption,
		),
		schema: {
			configuration: null,
			items: null,
			renderer: PLAIN_TEXT,
			system: null,
			custom: node.type,
			type: ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
		},
	};
};
