import ApplicationKeys, {
	type ApplicationKey,
} from '@atlassian/jira-common-constants/src/application-keys';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	type ProjectType,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import {
	LABELS_CF_TYPE,
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	TEXT_CF_TYPE,
	URL_CF_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
	DATE_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	USER_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	DATETIME_CF_TYPE,
	PARENT_CF_TYPE,
	BASELINE_START_CF_TYPE,
	BASELINE_END_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEAM_CF_TYPE,
	COLOR_CF_TYPE,
	EPIC_COLOR_TYPE,
	ISSUE_FIELD_NUMBER_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
	ISSUE_FIELD_DATETIME_CF_TYPE,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	ASSETS_CF_TYPE,
	RESPONDERS_CF_TYPE,
	MAJOR_INCIDENT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	CMDB_OBJECT_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	REQUEST_LANGUAGE_CF_TYPE,
	PEOPLE_CF_TYPE,
	REQUEST_FEEDBACK_CF_TYPE,
	APPROVALS_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
	REQUEST_TYPE_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	PROJECT_PICKER_CF_TYPE,
	READ_ONLY_CF_TYPE,
	EPIC_STATUS_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	EPIC_NAME_TYPE,
	CATEGORY_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
	GOALS_CF_TYPE,
	type EPIC_TYPE,
	type SPRINT_TYPE,
	type STORY_POINTS_TYPE,
} from '@atlassian/jira-platform-field-config';

// Foundation Items (not configurable)
export const ISSUE_TYPE = 'issuetype';
export const ISSUE_SUMMARY = 'summary';
export const SUBTASK_PARENT = 'parent';
export const ISSUE_PARENT = 'parent-issue';
export const PARENT_ISSUE = 'parent'; // new Hierarchy unification parent field
export const WATCHES = 'watches';
export const VOTES = 'votes';
export const SECURITY = 'security';
export const ISSUE_RESTRICTION = 'issuerestriction';

// Context Items
export const ASSIGNEE = 'assignee';
export const REPORTER = 'reporter';
export const DUE_DATE = 'duedate';
export const PRIORITY = 'priority';
export const LABELS = 'labels';
export const COMPONENTS = 'components';
export const DEV_SUMMARY = 'devSummary';
export const RELEASES = 'releases';
export const SPRINT = 'sprint';
export const STORY_POINTS = 'storyPointsField';
export const EPIC = 'epic';
export const FIX_VERSIONS = 'fixVersions';
export const AFFECTS_VERSIONS = 'versions';
export const TIME_TRACKING = 'timetracking';
export const TIME_ESTIMATE = 'timeoriginalestimate';
export const STATUS = 'status';
export const RESOLUTION = 'resolution';
export const CREATED = 'created';
export const UPDATED = 'updated';
export const SLA_PANEL = 'SLA_PANEL';
export const LINKED_ALERTS = 'LINKED_ALERTS';
export const CHAT_CHANNEL = 'CHAT_CHANNEL';
export const MSTEAMS_CHANNEL = 'MSTEAMS_CHANNEL';
export const ZOOM_MEETING = 'ZOOM_MEETING';
export const CONFERENCE_CALL = 'CONFERENCE_CALL';
export const KNOWLEDGE_BASE = 'KNOWLEDGE_BASE';
export const SENTIMENT = 'SENTIMENT';
export const ASSET_ONBOARDING = 'ASSET_ONBOARDING';
export const MANAGE_STAKEHOLDERS = 'MANAGE_STAKEHOLDERS';

// Content Items (not yet configurable)
export const SUMMARY = 'summary';
export const DESCRIPTION = 'description';
export const COMMENT = 'comment';
export const EPIC_ISSUES = 'epic-issues'; // this field is only served in classic projects
export const CHILDREN_ISSUES = 'children-issues';
export const ENVIRONMENT = 'environment';
export const SUBTASKS = 'subtasks';
export const DATA_CLASSIFICATION_FIELD = 'dataclassification';

export const PARENT = PARENT_CF_TYPE;
export const PORTFOLIO_CHILD_ISSUES = 'portfolio-child-issues';

// Issue actions
export const FLAG_ACTIONS = 'flagActions';
export const CARD_COVER_ACTIONS = 'cardCoverActions';

export type IssueAppConfiguration = {
	applicationKey?: ApplicationKey;
	issueActions?: string[];
	ecosystemEnabled?: boolean;
};

export type ProjectConfig = {
	projectType: ProjectType;
	isSimplified: boolean;
};

/**
 * These fields are represented in Jira like `customfield_xxx` but we want to treat them like system fields (same way as e.g. labels field).
 * Because it is `customfield_xxx`, the only way to reference them is via their customFieldType.
 */
type SpecialFieldTypes = {
	epic: typeof EPIC_TYPE;
	sprint: typeof SPRINT_TYPE;
	storyPointsField: typeof STORY_POINTS_TYPE;
	['com.atlassian.teams:rm-teams-custom-field-team']: typeof TEAM_CF_TYPE;
	['com.atlassian.jpo:jpo-custom-field-parent']: typeof PARENT_CF_TYPE;
	['com.atlassian.jpo:jpo-custom-field-baseline-start']: typeof BASELINE_START_CF_TYPE;
	['com.atlassian.jpo:jpo-custom-field-baseline-end']: typeof BASELINE_END_CF_TYPE;
	['com.pyxis.greenhopper.jira:jsw-issue-color']: typeof COLOR_CF_TYPE;
	['com.pyxis.greenhopper.jira:gh-epic-color']: typeof EPIC_COLOR_TYPE;
	['com.pyxis.greenhopper.jira:gh-epic-label']?: typeof EPIC_NAME_TYPE;
};
export const specialFields = (): SpecialFieldTypes => {
	const fields: SpecialFieldTypes = {
		[EPIC]: 'com.pyxis.greenhopper.jira:gh-epic-link',
		[SPRINT]: 'com.pyxis.greenhopper.jira:gh-sprint',
		[STORY_POINTS]: 'com.atlassian.jira.plugin.system.customfieldtypes:storypoint',
		[TEAM_CF_TYPE]: TEAM_CF_TYPE,
		[PARENT_CF_TYPE]: PARENT_CF_TYPE,
		[BASELINE_START_CF_TYPE]: BASELINE_START_CF_TYPE,
		[BASELINE_END_CF_TYPE]: BASELINE_END_CF_TYPE,
		[COLOR_CF_TYPE]: COLOR_CF_TYPE,
		[EPIC_COLOR_TYPE]: EPIC_COLOR_TYPE,
		[EPIC_NAME_TYPE]: EPIC_NAME_TYPE,
	};

	return fields;
};

export const ecosystemCustomFields = [
	ISSUE_FIELD_NUMBER_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
	ISSUE_FIELD_DATETIME_CF_TYPE,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
];

const serviceDeskCustomFields = [
	REQUEST_PARTICIPANTS_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	REQUEST_LANGUAGE_CF_TYPE,
	REQUEST_FEEDBACK_CF_TYPE,
	APPROVALS_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
	REQUEST_TYPE_CF_TYPE,
	CMDB_OBJECT_CF_TYPE,
	RESPONDERS_CF_TYPE,
	MAJOR_INCIDENT_CF_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
];

export const customFieldsToDisplay = () => [
	LABELS_CF_TYPE,
	TEXT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	URL_CF_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	VERSION_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	DATE_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	USER_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	DATETIME_CF_TYPE,
	ASSETS_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	PEOPLE_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	PROJECT_PICKER_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	READ_ONLY_CF_TYPE,
	EPIC_STATUS_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	CATEGORY_TYPE,
	GOALS_CF_TYPE,
	...ecosystemCustomFields,
	...serviceDeskCustomFields,
];

// @ts-expect-error - Missing actual product
export const itemDocumentationUrls: Record<ProjectType, string> = {
	[SOFTWARE_PROJECT]:
		'https://confluence.atlassian.com/jirasoftwarecloud/configure-field-layout-in-the-issue-view-961798059.html',
	[CORE_PROJECT]:
		'https://confluence.atlassian.com/jiracorecloud/configure-field-layout-in-the-issue-view-961798123.html',
	[SERVICE_DESK_PROJECT]:
		'https://support.atlassian.com/jira-service-desk-cloud/docs/what-are-the-different-sections-in-the-issue-view/',
};

export const emptyConfiguration: () => IssueAppConfiguration = () => ({
	issueActions: [],
});

export const coreConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.CORE_ISSUE,
	issueActions: [],
	ecosystemEnabled: true,
});

export const softwareConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.SOFTWARE_ISSUE,
	issueActions: [FLAG_ACTIONS],
	ecosystemEnabled: true,
});

export const simpleSoftwareBoardConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.SIMPLE_SOFTWARE_BOARD,
	issueActions: [FLAG_ACTIONS, CARD_COVER_ACTIONS],
	ecosystemEnabled: true,
});

export const standardRoadmapConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.STANDARD_ROADMAP_ISSUE,
	issueActions: [FLAG_ACTIONS],
	ecosystemEnabled: true,
});

export const bitbucketConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.BITBUCKET_ISSUE,
	issueActions: [],
});

export const mobileConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.MOBILE_ISSUE,
	issueActions: [],
});

export const serviceDeskConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.SERVICE_DESK,
	issueActions: [],
	ecosystemEnabled: true,
});

export const connectConfiguration: () => IssueAppConfiguration = () => ({
	applicationKey: ApplicationKeys.CONNECT_ISSUE,
	issueActions: [],
	ecosystemEnabled: true,
});

export const getIssueConfiguration = (config: ProjectConfig | null) => {
	if (config) {
		switch (config.projectType) {
			case SOFTWARE_PROJECT:
				if (config.isSimplified) {
					return simpleSoftwareBoardConfiguration();
				}
				return softwareConfiguration();
			case CORE_PROJECT:
				return coreConfiguration();
			case SERVICE_DESK_PROJECT:
				return serviceDeskConfiguration();
			default:
				// fallback to sofware config
				return softwareConfiguration();
		}
	}
	return softwareConfiguration();
};
