import React, { useCallback, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import Spinner from '@atlaskit/spinner';
import { useThemeObserver, token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JSM_EMPTY_QUEUE_CREATE_REQUEST } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import usePracticeRequestTypesService from '@atlassian/jira-servicedesk-queues-common/src/services/practice-request-types';
import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '../../../constants.tsx';
import { PRACTICE_DATA } from './constants';
import { EmptyUnconfiguredPracticeQueue } from './empty-unconfigured-practice-queue';

export type Props = {
	projectId: number;
};

const openCreateIssueDialog =
	(queueCategory: string = NO_CATEGORY) =>
	(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'emptyQueueCreateIssueButton', { queueCategory });
		const buttonElement = event.currentTarget;
		// eslint-disable-next-line jira/wrm/no-load-bridge
		loadBridge({
			name: 'quick-edit/init/create-dialogs',
			wrmKeys: 'wrc!com.atlassian.jira.jira-quick-edit-plugin:quick-create-issue-bootstrap',
			// @ts-expect-error - TS2345 - Argument of type '({ showCreateDialog }: { showCreateDialog: any; }) => void' is not assignable to parameter of type '(value: unknown) => void | PromiseLike<void>'.
		}).then(({ showCreateDialog }) => {
			// the issue create dialog will read attributes off the event's target,
			// which in the case of our AK button could be the outer `button`, outer `span` or
			// or inner `span` so we reassign the target to the button so that those attributes
			// will always be present
			const eventWithButtonTarget = { ...event, target: buttonElement };
			showCreateDialog(eventWithButtonTarget);
		});
	};

export const shouldUseModernGic = () =>
	Boolean(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.IssueCreate && window.IssueCreate.Visibility && window.IssueCreate.Visibility.isEnabled,
	);

export const EmptyPracticeQueue = (props: Props) => {
	const { projectId } = props;

	const { formatMessage } = useIntl();
	const [practiceType] = usePathParam('practiceType');
	const practice = toItsmPractice(practiceType);
	const { colorMode } = useThemeObserver();
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();

	const openCreateIssueDialogModern = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'emptyQueueCreateIssueButton', {
				queueCategory: practice,
			});
			openIssueCreateModal({
				triggerPointKey: JSM_EMPTY_QUEUE_CREATE_REQUEST,
			});
		},
		[openIssueCreateModal, practice],
	);

	const { data, loading, error } = usePracticeRequestTypesService(projectId, practice);

	if (loading) {
		return (
			<SpinnerContainer>
				<Spinner size="large" />
			</SpinnerContainer>
		);
	}

	if (practice !== NO_CATEGORY && data && !data.ticketTypes.length) {
		return <EmptyUnconfiguredPracticeQueue />;
	}

	const { headerMessage, descriptionMessage, buttonLabel, getImageUrl, renderImage } =
		PRACTICE_DATA[practice];
	const itsmCategoryToPreSelect = practice !== NO_CATEGORY ? toItsmPractice(practice) : undefined;

	const renderImageWithSize = () =>
		getAkEmptyStateRenderImageFn(renderImage)({
			maxImageHeight: 250,
			maxImageWidth: 250,
		});
	return (
		<EmptyState
			imageUrl={fg('jsm_queues_consolidate_jfe_assets') ? undefined : getImageUrl(colorMode)}
			renderImage={fg('jsm_queues_consolidate_jfe_assets') ? renderImageWithSize : undefined}
			header={formatMessage(headerMessage)}
			description={formatMessage(descriptionMessage)}
			headingLevel={2}
			primaryAction={
				error ? undefined : (
					<Button
						appearance="primary"
						onClick={
							shouldUseModernGic() ? openCreateIssueDialogModern : openCreateIssueDialog(practice)
						}
						data-pid={projectId}
						data-itsm-category={itsmCategoryToPreSelect}
						// rendered as `data-testid` on the button element
						testId="servicedesk-queues-empty-queue.common.ui.empty-practice-queue.create-issue-button"
					>
						{formatMessage(buttonLabel)}
					</Button>
				)
			}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			testId="servicedesk-queues-empty-queue.common.ui.empty-practice-queue.empty-practice-queue"
		/>
	);
};

// matches the margin and max-width of atlaskit EmptyState component
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	margin: `${token('space.600', '48px')} auto`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 58}px`,
});
