import React, { type ReactNode } from 'react';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { Redirect, usePathParam } from '@atlassian/react-resource-router';
import AgentCheck from './agent-check';

type Props = {
	children: ReactNode;
};

const ProductAccessCheck = ({ children }: Props) => {
	const { hasServiceDeskAccess } = useApplicationPermissions();
	const [projectKey = ''] = usePathParam('projectKey');

	if (projectKey) {
		return <AgentCheck>{children}</AgentCheck>;
	}

	return hasServiceDeskAccess ? <>{children}</> : <Redirect to="/jira" />;
};

export default ProductAccessCheck;
