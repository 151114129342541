import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type CONNECT_TEXT_GQL_FIELD,
	ISSUE_FIELD_STRING_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggConnectTextToLegacyField: AggTransformerFunction<
	typeof CONNECT_TEXT_GQL_FIELD
> = (node) => ({
	...transformAggNodeToLegacyField(node),
	value: node.text,
	schema: {
		configuration: null,
		items: null,
		renderer: PLAIN_TEXT,
		custom: node.type,
		system: null,
		type: ISSUE_FIELD_STRING_CF_TYPE,
	},
});
