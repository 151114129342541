import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type DATE_TIME_GQL_FIELD,
	CREATED,
	DATETIME_CF_TYPE,
	FIRST_RESPONSE_DATE_CF_TYPE,
	ISSUE_FIELD_DATETIME_CF_TYPE,
	RESOLUTION_DATE,
	REQUEST_FEEDBACK_DATE_CF_TYPE,
	UPDATED,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

const defaultSchema = {
	type: ISSUE_FIELD_DATETIME_CF_TYPE,
	custom: null,
	system: null,
	configuration: null,
	items: null,
	renderer: PLAIN_TEXT,
} as const;

export const transformAggDateTimePickerToLegacyField: AggTransformerFunction<
	typeof DATE_TIME_GQL_FIELD
> = (node) => {
	switch (node.type) {
		case CREATED:
		case UPDATED:
			return {
				...transformAggNodeToLegacyField(node),
				value: node.dateTime || '',
				schema: {
					...defaultSchema,
					system: node.type,
					renderer: null,
				},
			};
		case RESOLUTION_DATE:
			return {
				...transformAggNodeToLegacyField(node),
				value: node.dateTime ?? null,
				schema: {
					...defaultSchema,
					system: node.type,
					renderer: null,
				},
			};
		case DATETIME_CF_TYPE:
		case FIRST_RESPONSE_DATE_CF_TYPE:
		case REQUEST_FEEDBACK_DATE_CF_TYPE:
			return {
				...transformAggNodeToLegacyField(node),
				value: node.dateTime ?? null,
				schema: {
					...defaultSchema,
					custom: node.type,
				},
			};
		default:
			return undefined;
	}
};
