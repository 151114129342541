import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AssigneeInitialization } from '../../../../model/fields/json-fields/system-fields/types';
import getCurrentUser$ from '../../../../services/current-user/get';
import { INITIALIZE_COLUMN } from '../../../../state/actions/columns';
import { setAssigneeInitializationStateAction } from '../../../../state/actions/field/initialization';
import type { State } from '../../../../state/reducers/types';
import { isTableDisabled } from '../../../../state/selectors/ui';

let cachedAssigneeInitializationResponse: AssigneeInitialization | undefined;

// Exported for testing
export const resetCache = () => {
	cachedAssigneeInitializationResponse = undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(INITIALIZE_COLUMN)
		.filter((action) => action.payload.column.fieldType === 'assignee')
		.switchMap(() => {
			if (isTableDisabled(store.getState())) {
				return Observable.empty<never>();
			}

			if (cachedAssigneeInitializationResponse) {
				return Observable.of(
					setAssigneeInitializationStateAction(cachedAssigneeInitializationResponse),
				);
			}
			return getCurrentUser$()
				.mergeMap((response) => {
					cachedAssigneeInitializationResponse = response;
					return Observable.of(setAssigneeInitializationStateAction(response));
				})
				.catch((error: FetchError) => {
					log.safeWarnWithoutCustomerData(
						'issue-table.ops.field.initialize.assignee',
						`Failed to initialize assignee. Received status code ${error.statusCode}`,
					);
					return Observable.empty<never>();
				});
		});
