import { Component } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { asyncWrmRequire } from '../async-wrm';
import type { Props, State } from './types';

/**
 * Manages asynchronous loading of modules with state tracking for progress and errors.
 * It triggers a callback post-loading or upon encountering errors, facilitating dynamic
 * module loading with user feedback.
 */
// eslint-disable-next-line jira/react/no-class-components
export class WRMModuleLoader extends Component<Props, State> {
	static defaultProps = {
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onLoad: () => {},
	};

	state: State = {
		loading: true,
		error: null,
	};

	componentDidMount() {
		this.getModules();
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.modules !== this.props.modules) {
			this.getModules();
		}
		if (prevState.loading && !this.state.loading) {
			if (!this.state.error) {
				this.props.onLoad();
			}
		}
	}

	getModules() {
		asyncWrmRequire(this.props.modules)
			.then(() => {
				this.setState({
					loading: false,
					error: null,
				});
			})
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					'wrm-module-loader.getModules',
					'asyncWrmRequire error',
					error,
				);
				this.setState({
					loading: false,
					error,
				});
			});
	}

	render() {
		const { loading, error } = this.state;
		return this.props.children({
			loading,
			error,
		});
	}
}
