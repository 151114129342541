import ChangesEmptyQueueIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/heroes/components/software-development';
import ProblemsEmptyQueueIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/target-single';
import AllTicketsEmptyQueueIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/ticket-dispensor';
import ServiceRequestsEmptyQueueIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/balloons';
import PostIncidentReviewsEmptyQueueIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/people-working-on-computers';
import IncidentsEmptyQueueIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/task-board-with-people';
import {
	CHANGES,
	INCIDENTS,
	PROBLEMS,
	SERVICE_REQUEST,
	POST_INCIDENT_REVIEWS,
	NO_CATEGORY,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import AllTicketsEmptyQueueImage from '../../assets/all-tickets.svg';
import AllTicketsEmptyQueueImageInDark from '../../assets/all-ticketsDark.svg';
import ChangesEmptyQueueImage from '../../assets/changes.svg';
import ChangesEmptyQueueImageInDark from '../../assets/changesDark.svg';
import IncidentsEmptyQueueImage from '../../assets/incidents.svg';
import IncidentsEmptyQueueImageInDark from '../../assets/incidentsDark.svg';
import PostIncidentReviewsEmptyQueueImage from '../../assets/post-incident-reviews.svg';
import PostIncidentReviewsEmptyQueueImageInDark from '../../assets/post-incident-reviewsDark.svg';
import ProblemsEmptyQueueImage from '../../assets/problems.svg';
import ProblemsEmptyQueueImageInDark from '../../assets/problemsDark.svg';
import ServiceRequestsEmptyQueueImage from '../../assets/service-requests.svg';
import ServiceRequestsEmptyQueueImageInDark from '../../assets/service-requestsDark.svg';
import messages from './messages';

export const LIGHT_MODE = 'light' as const;
export const DARK_MODE = 'dark' as const;
export const AUTO = 'auto' as const;
export type ThemeMode = typeof DARK_MODE | typeof LIGHT_MODE | typeof AUTO | undefined;

export const PRACTICE_DATA = {
	[SERVICE_REQUEST]: {
		headerMessage: messages.serviceRequestsHeader,
		descriptionMessage: messages.serviceRequestsDescription,
		buttonLabel: messages.serviceRequestsCreateIssueButtonText,
		renderImage: ServiceRequestsEmptyQueueIllustration,
		imageUrl: ServiceRequestsEmptyQueueImage,
		getImageUrl: (currentTheme: ThemeMode) =>
			currentTheme === DARK_MODE
				? ServiceRequestsEmptyQueueImageInDark
				: ServiceRequestsEmptyQueueImage,
		learnMoreUrl:
			'https://support.atlassian.com/jira-service-management-cloud/docs/what-are-service-requests/',
	},
	[INCIDENTS]: {
		headerMessage: messages.incidentsHeader,
		descriptionMessage: messages.incidentsDescription,
		buttonLabel: messages.incidentsCreateIssueButtonText,
		renderImage: IncidentsEmptyQueueIllustration,
		imageUrl: IncidentsEmptyQueueImage,
		getImageUrl: (currentTheme: ThemeMode) =>
			currentTheme === DARK_MODE ? IncidentsEmptyQueueImageInDark : IncidentsEmptyQueueImage,
		learnMoreUrl:
			'https://support.atlassian.com/jira-service-management-cloud/docs/what-are-incidents/',
	},
	[PROBLEMS]: {
		headerMessage: messages.problemsHeader,
		descriptionMessage: messages.problemsDescription,
		buttonLabel: messages.problemsCreateIssueButtonText,
		renderImage: ProblemsEmptyQueueIllustration,
		imageUrl: ProblemsEmptyQueueImage,
		getImageUrl: (currentTheme: ThemeMode) =>
			currentTheme === DARK_MODE ? ProblemsEmptyQueueImageInDark : ProblemsEmptyQueueImage,
		learnMoreUrl:
			'https://support.atlassian.com/jira-service-management-cloud/docs/what-are-problems/',
	},
	[CHANGES]: {
		headerMessage: messages.changesHeader,
		descriptionMessage: messages.changesDescription,
		buttonLabel: messages.changesCreateIssueButtonText,
		renderImage: ChangesEmptyQueueIllustration,
		imageUrl: ChangesEmptyQueueImage,
		getImageUrl: (currentTheme: ThemeMode) =>
			currentTheme === DARK_MODE ? ChangesEmptyQueueImageInDark : ChangesEmptyQueueImage,
		learnMoreUrl:
			'https://support.atlassian.com/jira-service-management-cloud/docs/what-are-changes/',
	},
	[POST_INCIDENT_REVIEWS]: {
		headerMessage: messages.postIncidentReviewsHeader,
		descriptionMessage: messages.postIncidentReviewsDescription,
		buttonLabel: messages.postIncidentReviewsCreateIssueButtonText,
		renderImage: PostIncidentReviewsEmptyQueueIllustration,
		imageUrl: PostIncidentReviewsEmptyQueueImage,
		getImageUrl: (currentTheme: ThemeMode) =>
			currentTheme === DARK_MODE
				? PostIncidentReviewsEmptyQueueImageInDark
				: PostIncidentReviewsEmptyQueueImage,
		learnMoreUrl:
			'https://support.atlassian.com/jira-service-management-cloud/docs/what-are-post-incident-reviews/',
	},
	[NO_CATEGORY]: {
		headerMessage: messages.allTicketsHeader,
		descriptionMessage: messages.allTicketsDescription,
		buttonLabel: messages.allTicketsCreateIssueButtonText,
		renderImage: AllTicketsEmptyQueueIllustration,
		imageUrl: AllTicketsEmptyQueueImage,
		getImageUrl: (currentTheme: ThemeMode) =>
			currentTheme === DARK_MODE ? AllTicketsEmptyQueueImageInDark : AllTicketsEmptyQueueImage,
		learnMoreUrl: undefined,
	},
} as const;
