import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AvatarUrls, UserDTO } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { ServerRestUser } from './user-type';

export type ApprovalDate = {
	epochMillis: number;
};

export type GroupId = string;
export const toGroupId: (arg1: string) => GroupId = (x) => x;

export type UserApproverPrincipal = {
	type: 'user';
	meta?: {
		jiraRest: string;
	};
	id: AccountId;
	name: string;
	avatarUrl?: string;
};

export type GroupApproverPrincipal = {
	type: 'group';
	meta: {
		memberCount: number;
		approvedCount: number;
	};
	id?: GroupId;
	name: string;
};

export type ApproverPrincipals = UserApproverPrincipal | GroupApproverPrincipal;

export const APPROVER_PENDING = 'pending' as const;
export const APPROVER_APPROVED = 'approved' as const;
export const APPROVER_DECLINED = 'declined' as const;

/**
 * Possible decisions made by an approver. Also used as the outcome of the approval, based on the approvals provided by all approvers.
 */
export type ApprovalDecisionStatus =
	| typeof APPROVER_PENDING
	| typeof APPROVER_APPROVED
	| typeof APPROVER_DECLINED;

export const APPROVAL_OK = 'OK' as const;
export const APPROVAL_INSUFFICIENT_APPROVERS = 'INSUFFICIENT_APPROVERS' as const;

/**
 * Represents whether an approval can be achieved or not.
 */
export type ApprovalState = typeof APPROVAL_OK | typeof APPROVAL_INSUFFICIENT_APPROVERS;

export type ApprovedStatus = {
	name: string;
	categoryId: string;
};

export type ApprovalCondition = {
	type: string;
	value: string;
};

export type ApprovalConfiguration = {
	approvers: {
		type: string;
		customFieldName: string;
		customFieldId: string;
	};
	condition: ApprovalCondition;
};

export type ApprovalStatus = {
	name: string;
	category: number;
};

/**
 * User providing approval to a customer request, returned by the REST API.
 *
 * See: https://developer.atlassian.com/cloud/jira/service-desk/rest/api-group-request/#api-rest-servicedeskapi-request-issueidorkey-approval-approvalid-get
 */
export type ServerApprover = UserDTO & { avatarUrl?: string };

/**
 * Contains the user providing the approval to a customer request, along wih their decision.
 *
 * See: https://developer.atlassian.com/cloud/jira/service-desk/rest/api-group-request/#api-rest-servicedeskapi-request-issueidorkey-approval-approvalid-get
 */
export interface ServerApproverDTO {
	approver: ServerApprover;
	approverDecision: ApprovalDecisionStatus;
}

/**
 * Details of an approval to a customer request, returned by the REST API.
 *
 * See: https://developer.atlassian.com/cloud/jira/service-desk/rest/api-group-request/#api-rest-servicedeskapi-request-issueidorkey-approval-get
 */
export interface ServerApproval {
	id: string;
	name: string;
	finalDecision: ApprovalDecisionStatus;
	canAnswerApproval: boolean;
	approvers: ServerApproverDTO[];
	configuration: ApprovalConfiguration;
	status?: ApprovalStatus | null;
	completedDate?: ApprovalDate;
	createdDate?: ApprovalDate;
	decisions?: ServerApproverDTO[];
	approverPrincipals?: ApproverPrincipals[] | ReadonlyArray<GroupApproverPrincipal>;
	pendingApprovalCount: number;
	excludedApprovers?: ReadonlyArray<ServerApprover>;
	approvalState?: ApprovalState;
	approvedStatus?: ApprovedStatus;
}

export type CompletedApprovalResponse = {
	id: string;
	name: string;
	finalDecision: ApprovalDecisionStatus;
	approvers: ServerApproverDTO[];
	status: ApprovalStatus;
	completedDate: ApprovalDate;
	createdDate?: ApprovalDate;
};

export type ApprovalValue = {
	activeApproval: ServerApproval;
	completedApprovals: CompletedApprovalResponse[];
};

/**
 * Transformed server user details used to display the approver in the Approval Panel.
 */
export interface Approver extends ServerApprover {
	accountId: AccountId;
	avatarUrl?: string;
	displayName: string;
	isFromLinkedField?: boolean;
	// Deprecated field
	key?: string;
}

/**
 * User details stored in the Approvers list. This shape is derived from merging/adding/removing approvers.
 */
export type ApproverFieldValue = {
	id?: AccountId;
	accountType?: string;
	active?: boolean;
	avatarUrls?: Partial<AvatarUrls> & { ['24x24']: string };
} & Omit<ServerRestUser, 'accountType' | 'active' | 'avatarUrls'>;

/**
 * Details stored if the approver is a group.
 */
export interface GroupApproverFieldValue {
	name: string;
	self?: string;
}

/**
 * Transformed server approver detail containing the user and their decision.
 */
export interface ApproverDTO {
	approver: Approver;
	// Optional because `Approver`s can be transformed to `ApproverDTO` as well
	approverDecision?: ApprovalDecisionStatus;
}

/**
 * Transformed server approval to a customer request, used by the Approval Panel.
 */
export interface Approval {
	id: string;
	name: string;
	finalDecision: ApprovalDecisionStatus;
	approvers: ReadonlyArray<ApproverDTO>;
	canAnswerApproval: boolean;
	linkedFieldName: string;
	isSingleUserPicker: boolean;
	decisions?: ReadonlyArray<ApproverDTO>;
	approverPrincipals?: ApproverPrincipals[] | ReadonlyArray<GroupApproverPrincipal>;
	configuration: ApprovalConfiguration;
	pendingApprovalCount: number;
	excludedApprovers?: ReadonlyArray<Approver>;
	approvalState?: ApprovalState;
	approvedStatus?: ApprovedStatus;
}
