import { connect } from '../../../../../common/table-redux';
import { getAddBarOverflow } from '../../../../../state/consumer/selectors/rows/index.tsx';
// eslint-disable-next-line import/order
import {
	getVisibleAdditionalColumnsWidth,
	isAddLinkShown,
	getTableRowsHeight,
	getTableContentHeight,
} from '../../../../../state/selectors/index.tsx';

import type { State } from '../../../../../state/types';
import AdditionalColumnsContainer from '../../../common/additional-columns-container';
import ScrollArea from './index-dumb';

export default connect(
	(state: State) => ({
		rowsHeight: getTableRowsHeight(state),
		contentHeight: getTableContentHeight(state),
		contentWidth: getVisibleAdditionalColumnsWidth(state),
		isAddLinkShown: isAddLinkShown(state),
		addBarOverflow: getAddBarOverflow(state),
		AdditionalColumnsContainer,
	}),
	{},
)(ScrollArea);
