import {
	type GOALS_GQL_FIELD,
	GOALS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggGoalsToLegacyField: AggTransformerFunction<typeof GOALS_GQL_FIELD> = (
	node,
) => {
	if (node.type !== GOALS_CF_TYPE) return undefined;

	const value = node.selectedGoals
		? {
				goals:
					node.selectedGoals?.edges
						?.filter((edge) => edge?.node !== null)
						.map((edge) => ({
							id: edge?.node?.id || '',
							name: edge?.node?.name || '',
							status: edge?.node?.status || null,
						})) || [],
				totalCount: node.selectedGoals?.totalCount || 0,
			}
		: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			configuration: null,
			custom: GOALS_CF_TYPE,
			items: null,
			renderer: null,
			system: null,
			type: GOALS_CF_TYPE,
		},
	};
};
