import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type JSM_SENTIMENT_GQL_FIELD,
	SENTIMENT_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggJSMSentimentToLegacyField: AggTransformerFunction<
	typeof JSM_SENTIMENT_GQL_FIELD
> = (node) => {
	if (node.type !== SENTIMENT_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: node.sentiment,
		schema: {
			configuration: null,
			custom: SENTIMENT_CF_TYPE,
			items: ISSUE_FIELD_STRING_CF_TYPE,
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};
};
