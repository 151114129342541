import React, { Component, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import includes from 'lodash/includes';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Checkbox as CheckboxDI } from '@atlaskit/checkbox';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line import/order
import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { BULK_SELECTION_LIMIT } from '@atlassian/jira-issue-table-selection-services/src/services/constants.tsx';
import {
	SelectedIssuesSubscriber as SelectedIssuesSubscriberDI,
	type SelectedIssuesSubscriberType,
} from '@atlassian/jira-issue-table-selection-services/src/services/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CellComponentProps } from '@atlassian/jira-virtual-table/src/model/cell-component';
import messages from './messages';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const checkboxCellPaddingLeft = `${1.5 * gridSize}px`;

type Props = CellComponentProps & {
	Checkbox: typeof CheckboxDI;
	SelectedIssuesSubscriber: SelectedIssuesSubscriberType;
	onChange: (
		arg1: {
			isShiftKeyDown: boolean;
			isSelected: boolean;
		},
		arg2: UIAnalyticsEvent,
	) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
class CheckboxWrapperWithoutIntl extends Component<Props> {
	static defaultProps = {
		Checkbox: CheckboxDI,
		SelectedIssuesSubscriber: SelectedIssuesSubscriberDI,
	};

	createOnChange({ selectedIssueKeys }: { selectedIssueKeys: IssueKey[] }) {
		return (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			const { onChange, rowId } = this.props;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			const isShiftKeyDown = (event.nativeEvent as any).shiftKey;
			onChange(
				{
					isSelected: !includes(selectedIssueKeys, toIssueKey(rowId)),
					isShiftKeyDown,
				},
				analyticsEvent,
			);
			fireUIAnalytics(analyticsEvent, 'issueTableBulkActionCheckbox');
		};
	}

	render() {
		const { SelectedIssuesSubscriber, rowId, Checkbox, intl } = this.props;
		return (
			<SelectedIssuesSubscriber>
				{({ issueKeys, limit, isLocked }) => {
					const issueKey = toIssueKey(rowId);
					const isChecked = includes(issueKeys, issueKey);

					if (isLocked) {
						return (
							<StyledWrapper isDisabled>
								<Checkbox isDisabled isChecked={isChecked} />
							</StyledWrapper>
						);
					}

					const isDisabled = !isChecked && issueKeys.length >= limit;
					return isDisabled ? (
						<StyledWrapper isDisabled>
							<Checkbox isDisabled isChecked={isChecked} />
							<Tooltip
								content={intl.formatMessage(messages.maximumReached, {
									limit: BULK_SELECTION_LIMIT,
								})}
								delay={0}
								position="bottom"
							>
								<TooltipHolder />
							</Tooltip>
						</StyledWrapper>
					) : (
						// @ts-expect-error - TS2741 - Property 'isDisabled' is missing in type '{ children: Element; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { isDisabled: boolean; }, any>>'.
						<StyledWrapper>
							<Checkbox
								aria-label={intl.formatMessage(messages.selectThisCheckboxLabel, {
									issueKey,
								})}
								isChecked={isChecked}
								onChange={this.createOnChange({ selectedIssueKeys: issueKeys })}
							/>
						</StyledWrapper>
					);
				}}
			</SelectedIssuesSubscriber>
		);
	}
}

export const CheckboxWrapper = injectIntl(CheckboxWrapperWithoutIntl);

export default CheckboxWrapper;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div<{ isDisabled: boolean }>({
	userSelect: 'none',
	position: 'relative',
	boxSizing: 'border-box',
	width: '100%',
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')} ${token('space.100', '8px')} ${checkboxCellPaddingLeft}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	input: ({ isDisabled }) => ({
		cursor: isDisabled ? 'not-allowed' : 'pointer',
	}),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipHolder = styled.div({
	cursor: 'not-allowed',
	position: 'absolute',
	top: token('space.100', '8px'),
	left: token('space.100', '8px'),
	right: token('space.100', '8px'),
	bottom: token('space.100', '8px'),
});
