import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { ServerRestResolution } from '@atlassian/jira-issue-shared-types/src/common/types/resolution-type.tsx';
import {
	type RESOLUTION_GQL_FIELD,
	RESOLUTION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';

const resolutionTransformer = (
	resolution:
		| {
				readonly id: string;
				readonly name: string | null | undefined;
				readonly resolutionId: string;
		  }
		| null
		| undefined,
): ServerRestResolution => {
	if (resolution == null) return null;

	return {
		id: resolution.resolutionId,
		name: resolution.name || '',
	};
};

export const transformAggResolutionToLegacyField: AggTransformerFunction<
	typeof RESOLUTION_GQL_FIELD
> = (node) => {
	if (node.type !== RESOLUTION_TYPE) return undefined;

	const value = resolutionTransformer(node.resolution);
	const allowedValues = transformAggConnection(node.resolutions, resolutionTransformer);

	return {
		...transformAggNodeToLegacyField(node),
		value,
		allowedValues,
		schema: {
			type: RESOLUTION_TYPE,
			renderer: PLAIN_TEXT,
			custom: null,
			system: RESOLUTION_TYPE,
			items: null,
			configuration: null,
		},
	};
};
