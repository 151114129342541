import type { StandaloneCustomReporter } from '@atlassian/jira-browser-metrics/src/types';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import type { MetricsProps } from '@atlassian/jira-issue-view-common-types/src/metric-types';
import type { DataProvider } from '@atlassian/jira-providers-issue/src/model/data-provider.tsx';
import type { Route } from '@atlassian/react-resource-router';

type ImmutableState = {
	isLoadedWithPage: boolean;
	isSPA: boolean;
	isRecentIssue: boolean;
	recentIssuesCount: number;
	analyticsSource: AnalyticsSource;
	correlationId: string;
	lazyMetricsProps: () => MetricsProps;
	timingDataReporter?: StandaloneCustomReporter;
	issueScaleabilityReporter: StandaloneCustomReporter;
	dataProvider?: DataProvider;
	route?: Route | null;
};

export default class PerformanceMetricsState {
	immutableState: ImmutableState;

	isLoadedWithPage: boolean;

	hasLoadedEditor = false;

	startTime: number = Math.floor(performance.now()); // start time in origin relative  millis

	// @ts-expect-error - TS2564 - Property 'previewEnd' has no initializer and is not definitely assigned in the constructor.
	previewEnd: number;

	// @ts-expect-error - TS2564 - Property 'fmp' has no initializer and is not definitely assigned in the constructor.
	fmp: number;

	// @ts-expect-error - TS2564 - Property 'interactiveEnd' has no initializer and is not definitely assigned in the constructor.
	interactiveEnd: number;

	// @ts-expect-error - TS2564 - Property 'trueInteractiveEnd' has no initializer and is not definitely assigned in the constructor.
	trueInteractiveEnd: number;

	constructor(state: ImmutableState) {
		this.immutableState = state;
		this.isLoadedWithPage = state.isLoadedWithPage;
	}

	lazyMetricsProps(): () => MetricsProps {
		return this.immutableState.lazyMetricsProps;
	}

	getDataProvider(): DataProvider | undefined {
		return this.immutableState.dataProvider;
	}

	getIsFullIssue(): boolean {
		return this.immutableState.analyticsSource === 'full-issue';
	}

	getIsLoadedWithPage(): boolean {
		return this.immutableState.isLoadedWithPage;
	}

	setIsLoadedWithPage(newValue: boolean) {
		this.isLoadedWithPage = newValue;
	}

	getIsSPA(): boolean {
		return this.immutableState.isSPA;
	}

	getIsRecentIssue(): boolean {
		return this.immutableState.isRecentIssue;
	}

	getRecentIssuesCount(): number {
		return this.immutableState.recentIssuesCount;
	}

	getAnalyticsSource() {
		return this.immutableState.analyticsSource;
	}

	getCorrelationId(): string {
		return this.immutableState.correlationId;
	}

	getIssueScaleabilityReporter(): StandaloneCustomReporter {
		return this.immutableState.issueScaleabilityReporter;
	}

	getStartTime(): number {
		return this.startTime;
	}

	setPreviewEnd(end: number | null) {
		this.previewEnd = end === null ? 0 : Math.floor(end);
	}

	getPreviewEnd(): number {
		return this.previewEnd;
	}

	setFMP(end: number | null) {
		this.fmp = end === null ? 0 : Math.floor(end);
	}

	getFMP(): number {
		return this.fmp;
	}

	setInteractiveEnd(millisSinceEpoch: number) {
		this.interactiveEnd = millisSinceEpoch;
	}

	getInteractiveEnd(): number {
		return this.interactiveEnd;
	}

	setTrueInteractiveEnd(millisSinceEpoch: number) {
		this.trueInteractiveEnd = Math.floor(millisSinceEpoch);
	}

	getTrueInteractiveEnd(): number {
		return Math.floor(this.trueInteractiveEnd);
	}

	getHasLoadedEditor(): boolean {
		return this.hasLoadedEditor;
	}

	getIsInitial(): boolean {
		return this.getIsFullIssue() && !this.getIsSPA();
	}

	getLocation(): string {
		const { route } = this.immutableState;
		const routeName = route?.name;
		return routeName || '';
	}
}
