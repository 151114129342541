/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { forwardRef, useCallback, useContext } from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { PopupContent } from '@atlaskit/popup/experimental';
import { token } from '@atlaskit/tokens';

import { useSideNavFlyoutLock } from '../../page-layout/side-nav/use-flyout-lock';

import { SetIsOpenContext } from './flyout-menu-item-context';

const flyoutMenuItemContentStyles = cssMap({
	root: {
		padding: token('space.100'),
		'@media (min-width: 48rem)': {
			width: '280px',
		},
	},
});

/**
 * Additional wrapper element to lock the side nav flyout when the popup content is rendered.
 */
const ContentWrapper = forwardRef<HTMLDivElement, FlyoutMenuItemContentProps>(
	({ children }, forwardedRef) => {
		useSideNavFlyoutLock();

		return <div ref={forwardedRef}>{children}</div>;
	},
);

export type FlyoutMenuItemContentProps = {
	children: React.ReactNode;
	/**
	 * A `testId` that is applied to the container element as the `data-testid` attribute.
	 */
	containerTestId?: string;
	onClose?: () => void;
};

/**
 * __FlyoutMenuItemContent__
 *
 * The content that appears when the flyout menu is open.
 */
export const FlyoutMenuItemContent = forwardRef<HTMLDivElement, FlyoutMenuItemContentProps>(
	({ children, containerTestId, onClose }, forwardedRef) => {
		const setIsOpen = useContext(SetIsOpenContext);

		const handleClose = useCallback(() => {
			onClose?.();
			setIsOpen(false);
		}, [setIsOpen, onClose]);

		return (
			<PopupContent
				appearance="UNSAFE_modal-below-sm"
				onClose={handleClose}
				placement="right-start"
				shouldFitViewport
				testId={containerTestId}
				xcss={flyoutMenuItemContentStyles.root}
			>
				{() => <ContentWrapper ref={forwardedRef}>{children}</ContentWrapper>}
			</PopupContent>
		);
	},
);
