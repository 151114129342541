import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/catch';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import log from '@atlassian/jira-common-util-logging/src/log';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { State } from '../state/reducers/types';
import bulkSelectEpic from './bulk-select';
import columnsEpic from './columns';
import fieldEpic from './field';
import issueEpic from './issue';
import scrollEpic from './scroll';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	combineEpics(...[fieldEpic, columnsEpic, scrollEpic, issueEpic, bulkSelectEpic])(
		action$,
		store,
	).catch((error, stream) => {
		log.safeErrorWithoutCustomerData('issue-table.ops', 'Unhandled error');
		fireErrorAnalytics({
			meta: {
				id: 'opsUnhandledError',
				packageName: 'jiraIssueTable',
				teamName: 'jsd-shield',
			},
			error: new Error(error),
		});
		return stream;
	});
