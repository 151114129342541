import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { AsyncQueuesAutomationMenu } from '@atlassian/jira-servicedesk-queues-automation-menu/src/async';

type Props = {
	isManuallySorted: boolean;
	isFavoriteEnabled: boolean;
	canManageQueue: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ResetSortingButton: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActionsMenu: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FavoriteButton: ComponentType<any>;
	showIssueFailureError: boolean;
	onDeleteComplete: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OpenInIssueNavButton: ComponentType<any>;
};

export default function HeaderActions({
	canManageQueue,
	isManuallySorted,
	isFavoriteEnabled,
	onDeleteComplete,
	showIssueFailureError,
	ActionsMenu,
	FavoriteButton,
	ResetSortingButton,
	OpenInIssueNavButton,
}: Props) {
	const shouldShowResetSortingButton =
		!showIssueFailureError && !canManageQueue && isManuallySorted;
	const hasMultipleActions = isFavoriteEnabled && (canManageQueue || shouldShowResetSortingButton);

	return (
		<Wrapper>
			<AsyncQueuesAutomationMenu />
			<>
				<OpenInIssueNavButton />
				<Spacer />
			</>
			{isFavoriteEnabled && <FavoriteButton />}
			{hasMultipleActions && <Spacer />}
			{canManageQueue && <ActionsMenu onDeleteComplete={onDeleteComplete} />}
			{shouldShowResetSortingButton && <ResetSortingButton />}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize}px`,
});
