import type { Watches } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import {
	type WATCHES_GQL_FIELD,
	WATCHES_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggJiraWatches } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

const transformAggWatchesToLegacyWatches = (watch: AggJiraWatches): Watches => ({
	watchCount: watch.count != null ? Number(watch.count) : 0,
	isWatching: watch.isWatching ?? false,
});

export const transformAggWatchesToLegacyField: AggTransformerFunction<typeof WATCHES_GQL_FIELD> = (
	node,
) => {
	if (node.type !== WATCHES_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: node.watch ? transformAggWatchesToLegacyWatches(node.watch) : null,
		schema: {
			configuration: null,
			custom: null,
			items: null,
			renderer: null,
			system: WATCHES_TYPE,
			type: WATCHES_TYPE,
		},
	};
};
