import { useContext, useEffect } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { SetFlyoutLockCount } from './flyout-lock-context';

/**
 * This hook will prevent the side nav from automatically collapsing
 * when it is in a flyout state.
 *
 * The intended use case for this hook is to allow popup menus in the
 * side nav to be usable while it is in a flyout state.
 *
 * ## Usage
 * The intended usage is to use this hook within the popup component
 * you are rendering. This way the side nav will be locked for
 * as long as the popup is open.
 */
export const useSideNavFlyoutLock = () => {
	const setFlyoutLock = useContext(SetFlyoutLockCount);

	useEffect(() => {
		if (fg('platform_design_system_team_layering_observer')) {
			// This feature flag moves the flyout lock logic to Popup, so this hook should be a no-op.
			return;
		}

		// This if statement is to prevent errors in test environments where window.matchMedia is not defined,
		// so consumers don't need to explicitly mock it in their tests.
		if (typeof window?.matchMedia !== 'function') {
			return;
		}

		const { matches } = window.matchMedia('(min-width: 64rem)');
		if (matches) {
			setFlyoutLock((current) => current + 1);
		}

		return () => {
			if (matches) {
				setFlyoutLock((current) => current - 1);
			}
		};
	}, [setFlyoutLock]);
};
