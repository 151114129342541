import React from 'react';
import AkLozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { statusCategoryToThemeMap, disabledColor } from '@atlassian/jira-common-styles/src/status';

export const Lozenge = ({
	children,
	statusCategoryId,
	isDisabled,
}: {
	statusCategoryId: number;
	children: React.ReactNode;
	isDisabled?: boolean;
}) => (
	<AkLozenge
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		appearance={statusCategoryToThemeMap[statusCategoryId] as ThemeAppearance}
		isBold
		{...(isDisabled
			? {
					style: {
						color: disabledColor.text,
						backgroundColor: disabledColor.bg,
					},
				}
			: {})}
	>
		{children}
	</AkLozenge>
);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { LozengeOld } from './main';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props as LozengeProps } from './types';
