import memoizeOne from 'memoize-one';
import {
	type SortOrder,
	NONE,
} from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import type { ColumnProps } from '@atlassian/jira-issue-table/src/model/index.tsx';
import { getFieldTypesEligibleForJsonDataAsSet } from '@atlassian/jira-servicedesk-queues-common/src/json-fields';
import type { Column } from '@atlassian/jira-servicedesk-queues-common/src/model';

type ColumnPropsOverrides = {
	minWidth?: number;
	maxWidth?: number;
	canBeMultiLine?: boolean;
};

const getStylingOverrides = (fieldType: string): ColumnPropsOverrides => {
	if (fieldType === 'assignee' && !getFieldTypesEligibleForJsonDataAsSet().has(fieldType)) {
		return {
			minWidth: 100,
			maxWidth: 200,
			canBeMultiLine: true,
		};
	}
	if (fieldType === 'summary') {
		return {
			minWidth: 262,
			maxWidth: 632,
			canBeMultiLine: true,
		};
	}
	return {};
};

export const getColumnsForIssueTable: (
	columns: Column[],
	onSortOrderChanged: (arg1: string, arg2: SortOrder) => void,
) => ColumnProps[] = memoizeOne((columns, onSortOrderChanged) =>
	// @ts-expect-error - TS7006 - Parameter 'column' implicitly has an 'any' type.
	columns.map((column) => {
		const { id, title, fieldType, currentSorting = NONE, sortable } = column;

		return {
			id,
			title,
			fieldType,
			currentSorting,
			onSortOrderChanged: sortable ? onSortOrderChanged : undefined,
			...getStylingOverrides(column.fieldType),
		};
	}),
);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getActiveElement = (): Element | null | undefined => document.activeElement;
