import { ISSUE_PANEL_MODULE } from '@atlassian/jira-forge-ui-constants';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { getEventId } from '../../common/utils';
import { fireUiEvent, fireTrackEvent, fireOperationalEvent } from '../../common/utils/consumers';
import { forgeModulesConcurrentLoadTime } from '../../common/utils/performance-analytics/main.tsx';
import {
	MODULE_TYPE_TRIGGER,
	MODULE_TYPE_EXTENSION,
	UI_ACTIONS,
	TRACK_ACTIONS,
	OPERATIONAL_ACTIONS,
} from '../../constants';

const moduleEventId = getEventId({
	module: ISSUE_PANEL_MODULE,
	moduleType: MODULE_TYPE_EXTENSION,
});

const triggerEventId = getEventId({
	module: ISSUE_PANEL_MODULE,
	moduleType: MODULE_TYPE_TRIGGER,
});

// This method will generate the same metric key as error boundary
export const fireOperationalFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireUiActionClickedEvent = (source?: Source, attributes?: Attributes) =>
	fireUiEvent(UI_ACTIONS.ACTION_CLICKED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireTrackActionSucceedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.ACTION_SUCCEED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalActionFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.ACTION_FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireTrackSavedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.SAVED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalSaveFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.SAVE_FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireUiRemoveClickedEvent = (source?: Source, attributes?: Attributes) =>
	fireUiEvent(UI_ACTIONS.REMOVE_CLICKED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireTrackRemovedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.REMOVED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalRemoveFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.REMOVE_FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireUiTriggerSelectedEvent = (
	extensionId: string,
	source?: Source,
	attributes?: Attributes,
) => {
	forgeModulesConcurrentLoadTime(extensionId).start({ startTime: performance.now() });

	return fireUiEvent(UI_ACTIONS.SELECTED, {
		id: triggerEventId,
		source,
		attributes,
	});
};

export const fireUiCollapseExpandClickedEvent = (source?: Source, attributes?: Attributes) =>
	fireUiEvent(UI_ACTIONS.COLLAPSE_EXPAND_CLICKED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireTrackUpdateSucceedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.UPDATE_SUCCEED, {
		id: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalUpdateFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.UPDATE_FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});
