import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type USER_PICKER_GQL_FIELD,
	ASSIGNEE_TYPE,
	ISSUE_FIELD_USER_CF_TYPE,
	REPORTER_TYPE,
	USER_CF_TYPE,
	LAST_UPDATER_OR_COMMENTER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';
import { transformJiraUserToLegacyServerUser } from './user-node-transformer';

export const transformAggSingleSelectUserPickerToLegacyField: AggTransformerFunction<
	typeof USER_PICKER_GQL_FIELD
> = (node) => {
	const legacyField = {
		...transformAggNodeToLegacyField(node),
		value: node.user ? transformJiraUserToLegacyServerUser(node.user) : null,
	};

	switch (node.type) {
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
			return {
				...legacyField,
				schema: {
					type: ISSUE_FIELD_USER_CF_TYPE,
					custom: null,
					system: node.type,
					renderer: PLAIN_TEXT,
					configuration: null,
					items: null,
				},
			};
		case USER_CF_TYPE:
		case LAST_UPDATER_OR_COMMENTER_CF_TYPE:
			return {
				...legacyField,
				schema: {
					type: ISSUE_FIELD_USER_CF_TYPE,
					custom: node.type,
					system: null,
					renderer: PLAIN_TEXT,
					configuration: null,
					items: null,
				},
			};
		default:
			return undefined;
	}
};
