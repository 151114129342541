import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { FlaggedValue } from '@atlassian/jira-issue-shared-types/src/common/types/flag-type.tsx';
import {
	type FLAG_GQL_FIELD,
	FLAGGED_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

const transformJiraFlagToLegacyFlag = (
	flag:
		| {
				readonly isFlagged: boolean | null | undefined;
		  }
		| null
		| undefined,
): FlaggedValue => {
	if (flag == null || flag.isFlagged == null) {
		return null;
	}

	return [
		{
			value: flag.isFlagged,
		},
	];
};

export const transformAggFlagToLegacyField: AggTransformerFunction<typeof FLAG_GQL_FIELD> = (
	node,
) => {
	// Agg flag field comes back as multichecbox CF type instead of current graphql flagged CF type
	if (node.type !== MULTI_CHECKBOXES_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: transformJiraFlagToLegacyFlag(node.flag),
		schema: {
			configuration: null,
			custom: FLAGGED_CF_TYPE,
			items: 'option',
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};
};
