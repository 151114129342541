// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import AnalyticsWrapper from './analytics-wrapper';

const LOCATION = 'servicedesk.queues.agent-view.view.layout.usage-sampler';
const INTERVAL = 300 * 1000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = Record<any, any>;

type State = {
	hasError: boolean;
};

export default class UsageObserver extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		this.intervalId = window.setInterval(() => this.forceUpdate(), INTERVAL);
	}

	componentDidCatch(error: Error) {
		this.setState({ hasError: true });
		fireErrorAnalytics({
			meta: {
				id: 'viewLayoutUsageSampling',
				packageName: 'jiraServicedeskQueuesAgentView',
				teamName: 'jsd-shield',
			},
			// @ts-expect-error - TS2345 - Argument of type 'Error' is not assignable to parameter of type 'string'.
			error: new Error(error),
		});
		log.safeErrorWithoutCustomerData(
			LOCATION,
			`[FD-3358] Error while sampling usage: ${JSON.stringify(error && error.stack)}`,
		);
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		this.intervalId && window.clearInterval(this.intervalId);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intervalId: any;

	render() {
		if (this.state.hasError) {
			return null;
		}
		return <AnalyticsWrapper />;
	}
}
