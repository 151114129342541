import React from 'react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import Alert from '../common/alert/view.tsx';
import messages from './messages';

type Props = {
	isLastColumn: boolean;
	message: string | MessageDescriptor;
};

function Error(props: Props) {
	const { isLastColumn, message } = props;
	const { formatMessage } = useIntl();

	const tooltipMessage = typeof message === 'string' ? message : formatMessage(message);

	return (
		<Alert
			isLastColumn={isLastColumn}
			tooltipMessage={tooltipMessage}
			Icon={ErrorIcon}
			color={token('color.icon.danger', colors.R400)}
			backgroundColor={token('color.background.danger', 'rgb(239, 239, 239)')}
		/>
	);
}

Error.defaultProps = {
	isLastColumn: false,
	message: messages.errorMessage,
};

export default Error;
