import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { QueuesCategoryState } from '../../types';
import {
	setQueuesCategoryStateInLocalStorage,
	getQueuesCategoryStateFromLocalStorage,
	getEvictedState,
} from '../local-storage';

export const MAX_CATEGORY_STATE_CACHE_LENGTH = 50;
export const MAX_CATEGORY_STATE_CACHE_ENTRY_AGE = 1000 * 60 * 60 * 24 * 28; // 28 days in milliseconds

type CategoryUpdate = {
	isPoppedOut: boolean;
	lastVisitedQueueId: string | null;
	isStarredExpanded: boolean;
	expandedGroups: string[];
	isAllQueuesOpen: boolean;
};

const createUpdateAction =
	<K extends keyof CategoryUpdate>(
		projectKey: string,
		practice: ItsmPractice,
		stateKey: K,
		value: CategoryUpdate[K],
	): Action<QueuesCategoryState> =>
	({ setState, getState }) => {
		const currentState = getState();
		const categoryKey = getCategoryStateKey(projectKey, practice);
		const newState = {
			...currentState,
			[categoryKey]: {
				...(currentState[categoryKey] || {}),
				[stateKey]: value,
				latestUpdateTimestamp: Date.now(),
			},
		};
		setState(newState);
		// Save an evicted state to local storage to prevent unbounded growth.
		const evictedState = getEvictedState(
			newState,
			MAX_CATEGORY_STATE_CACHE_LENGTH,
			MAX_CATEGORY_STATE_CACHE_ENTRY_AGE,
		);
		setQueuesCategoryStateInLocalStorage(evictedState);
	};

export const actions = {
	updatePoppedOutState: (
		projectKey: string,
		practice: ItsmPractice,
		isPoppedOut: boolean,
	): Action<QueuesCategoryState> =>
		createUpdateAction(projectKey, practice, 'isPoppedOut', isPoppedOut),
	updateLastVisitedQueueId: (
		projectKey: string,
		practice: ItsmPractice,
		queueId: string,
	): Action<QueuesCategoryState> =>
		createUpdateAction(projectKey, practice, 'lastVisitedQueueId', queueId),
	updateIsStarredExpanded: (
		projectKey: string,
		practice: ItsmPractice,
		isExpanded: boolean,
	): Action<QueuesCategoryState> =>
		createUpdateAction(projectKey, practice, 'isStarredExpanded', isExpanded),
	updateExpandedGroups: (
		projectKey: string,
		practice: ItsmPractice,
		groups: string[],
	): Action<QueuesCategoryState> =>
		createUpdateAction(projectKey, practice, 'expandedGroups', groups),
	updateIsAllQueuesOpen: (
		projectKey: string,
		practice: ItsmPractice,
		isOpen: boolean,
	): Action<QueuesCategoryState> =>
		createUpdateAction(projectKey, practice, 'isAllQueuesOpen', isOpen),
};

type Actions = typeof actions;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialState: QueuesCategoryState = {} as QueuesCategoryState;

const Store = createStore<QueuesCategoryState, Actions>({
	name: 'jsm-queues-category-state-store',
	initialState,
	actions,
});

export const CategoryStateContainer = createContainer<QueuesCategoryState, Actions>(Store, {
	onInit:
		() =>
		({ setState }) => {
			const stateFromStorage = getQueuesCategoryStateFromLocalStorage();
			if (stateFromStorage) {
				setState(stateFromStorage);
			}
		},
});

export const usePoppedOutState = createHook(Store, {
	selector: (state: QueuesCategoryState, categoryKey: string) =>
		state[categoryKey]?.isPoppedOut || false,
});

export const useLastVisitedQueueId = createHook(Store, {
	selector: (state: QueuesCategoryState, categoryKey: string) =>
		state[categoryKey]?.lastVisitedQueueId || null,
});

export const useIsStarredExpanded = createHook(Store, {
	selector: (state: QueuesCategoryState, categoryKey: string) =>
		state[categoryKey]?.isStarredExpanded || false,
});

export const useExpandedGroups = createHook(Store, {
	selector: (state: QueuesCategoryState, categoryKey: string) =>
		state[categoryKey]?.expandedGroups || [],
});

export const useIsAllQueuesOpen = createHook(Store, {
	selector: (state: QueuesCategoryState, categoryKey: string) =>
		state[categoryKey]?.isAllQueuesOpen || false,
});

export const getCategoryStateKey = (projectKey: string, practice: ItsmPractice): string =>
	`${projectKey}-${practice}`;
