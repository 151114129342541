import { combineReducers } from 'redux';
import analytic from './analytic';
import experienceTracking from './experience-tracking';
import filter from './filter';
import issues from './issues';
import practices from './practices';
import queue from './queue';
import ssrIssuesData from './ssr-issues-data';
import updateMetadata from './update-metadata';

const combinedReducer = combineReducers({
	issues,
	queue,
	updateMetadata,
	experienceTracking,
	practices,
	filter,
	analytic,
	ssrIssuesData,
});
export default combinedReducer;
