import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { State } from '../../../state/reducers/types';
import { getRefreshSidebar } from '../../../state/selectors/ui';
import { connect } from '../../../state/store';
import createEditableField from './editable-field/view.tsx';
import SingleUserSelect from './single-user-select';

const mapStateToProps = (state: State) => ({
	onRefreshSidebar: getRefreshSidebar(state),
});

// @ts-expect-error - TS2345 - Argument of type '<T extends UserFieldType>(props: FieldComponentProps<T>) => JSX.Element' is not assignable to parameter of type 'ComponentType<FieldComponentProps<"assignee" | "reporterWithIcon">>'.
const AssigneeField = createEditableField(SingleUserSelect);

export default componentWithCondition(
	() => ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff'),
	connect(mapStateToProps, {})(AssigneeField),
	AssigneeField,
);
