// LOCK_FIELD

import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { DataSelectorFieldValue, FieldType } from '../../../../model/fields/types';

export const FIELD_UPDATE_SUBMIT = 'state.actions.field.update.FIELD_UPDATE_SUBMIT' as const;

export type UpdateFieldAction<T extends FieldType> = {
	type: typeof FIELD_UPDATE_SUBMIT;
	payload: {
		issueKey: string;
		fieldId: string;
		fieldValue: DataSelectorFieldValue<T>;
		onSuccess: (arg1: DataSelectorFieldValue<T>) => void;
		onFailure: () => void;
	};
};

export const submitFieldUpdateAction = <T extends FieldType>(
	issueKey: string,
	fieldId: string,
	fieldValue: DataSelectorFieldValue<T>,
	onSuccess: (arg1: DataSelectorFieldValue<T>) => void,
	onFailure: () => void,
): UpdateFieldAction<T> => ({
	type: FIELD_UPDATE_SUBMIT,
	payload: {
		issueKey,
		fieldId,
		fieldValue,
		onSuccess,
		onFailure,
	},
});

// FIELD_UPDATE_SUCCESS

export const FIELD_UPDATE_SUCCESS = 'state.actions.field.update.FIELD_UPDATE_SUCCESS' as const;

export type UpdateFieldSuccessAction = {
	type: typeof FIELD_UPDATE_SUCCESS;
	payload: {
		issueKey: string;
		fieldId: string;
	};
};

export const updateFieldSuccessAction = (
	issueKey: string,
	fieldId: string,
): UpdateFieldSuccessAction => ({
	type: FIELD_UPDATE_SUCCESS,
	payload: {
		issueKey,
		fieldId,
	},
});

// FIELD_UPDATE_FAILURE

export const ERROR_RESPONSE = 'ERROR_RESPONSE' as const;

type FailureReason = {
	type: typeof ERROR_RESPONSE;
	statusCode: number;
	message: string;
};

export const FIELD_UPDATE_FAILURE = 'state.actions.field.update.FIELD_UPDATE_FAILURE' as const;

export type UpdateFieldFailureAction = {
	type: typeof FIELD_UPDATE_FAILURE;
	payload: {
		issueKey: string;
		fieldId: string;
		fieldType: string;
		fieldName: string;
		failureReason: FailureReason;
	};
};

export const updateFieldFailureErrorResponseAction = (
	issueKey: string,
	fieldId: string,
	fieldType: string,
	fieldName: string,
	error: FetchError,
): UpdateFieldFailureAction => ({
	type: FIELD_UPDATE_FAILURE,
	payload: {
		issueKey,
		fieldId,
		fieldType,
		fieldName,
		failureReason: {
			type: ERROR_RESPONSE,
			statusCode: error.statusCode,
			message: error.message,
		},
	},
});
