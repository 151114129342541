import { startOfDay, addDays, subDays } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import log from '@atlassian/jira-common-util-logging/src/log';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import {
	PAUSED,
	ONGOING,
	COMPLETED,
	SLA_CUSTOM_FIELD_TYPE,
	SERVICE_ENTITY_CUSTOM_FIELD_TYPE,
	CMDB_OBJECT_CUSTOM_FIELD_TYPE,
	RESPONDERS_CUSTOM_FIELD_TYPE,
	MAJOR_INCIDENT_CUSTOM_FIELD_TYPE,
	MULTI_CHECKBOX_CUSTOM_FIELD_TYPE,
	MULTI_SELECT_CUSTOM_FIELD_TYPE,
	MULTI_USER_CUSTOM_FIELD_TYPE,
	MULTI_VERSION_CUSTOM_FIELD_TYPE,
	MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE,
	ENTITLEMENT_CUSTOM_FIELD_TYPE,
	SENTIMENT_CUSTOM_FIELD_TYPE,
} from '@atlassian/jira-issue-table/src/model/fields/json-fields/custom-fields/types.tsx';
import { NEW_SLA_FORMAT } from '@atlassian/jira-servicedesk-sla-panel/src/common/constants';
import type { SLA_DISPLAY_FORMAT } from '@atlassian/jira-servicedesk-sla-panel/src/common/types';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import type { CompletedCycle, OngoingCycle } from '../../../../../../rest/issue/fields/types';
import type { FieldDataTransformer } from '../common/types';
import messages from './messages';

const LOCATION = 'servicedesk.queues.selectors.fields-transformers.json-fields.custom-fields';

const checkCycleValidity = (cycle: CompletedCycle | OngoingCycle): string[] => {
	const { remainingTime, goalDuration, elapsedTime } = cycle;

	const undefinedValues: Array<string> = [];

	if (!remainingTime) {
		undefinedValues.push('remainingTime');
	}

	if (!goalDuration) {
		undefinedValues.push('goalDuration');
	}

	if (!elapsedTime) {
		undefinedValues.push('elapsedTime');
	}

	return undefinedValues;
};

const isCompletedCycleValid = (cycle: CompletedCycle): boolean => {
	const undefinedValues = checkCycleValidity(cycle);

	if (undefinedValues.length > 0) {
		log.safeErrorWithoutCustomerData(
			LOCATION,
			`[FD-4303] CompletedCycle data is invalid: ${String(undefinedValues)}`,
		);
		return false;
	}

	return true;
};

const isOngoingCycleValid = (cycle: OngoingCycle): boolean => {
	const undefinedValues = checkCycleValidity(cycle);

	if (undefinedValues.length > 0) {
		log.safeErrorWithoutCustomerData(
			LOCATION,
			`[FD-4303] OngoingCycle data is invalid: ${String(undefinedValues)}`,
		);
		return false;
	}

	return true;
};

const getLastCompletedCycle = (completedCycles: CompletedCycle[]): CompletedCycle => {
	let lastCompletedCycle = completedCycles[0];

	completedCycles.forEach((cycle) => {
		if (cycle.stopTime.epochMillis > lastCompletedCycle.stopTime.epochMillis) {
			lastCompletedCycle = cycle;
		}
	});

	return lastCompletedCycle;
};

const getDays = (timezone: string) => {
	const date = utcToZonedTime(new Date(), timezone);
	return [startOfDay(date), startOfDay(addDays(date, 1)), startOfDay(subDays(date, 1))] as const;
};
// This shall be deleted when localised time shown feature is fully rolled out
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMatchingDay = (receivedDate: Date, timezone: string, formatMessage: any) => {
	const [today, tomorrow, yesterday] = getDays(timezone);

	const isSame = (dateOne: Date, dateTwo: Date) =>
		dateOne.getDate() === dateTwo.getDate() &&
		dateOne.getMonth() === dateTwo.getMonth() &&
		dateOne.getFullYear() === dateTwo.getFullYear();

	const isToday = isSame(today, receivedDate);
	const isTomorrow = isSame(tomorrow, receivedDate);
	const isYesterday = isSame(yesterday, receivedDate);

	let defaultDayFormat = format(receivedDate, 'd MMM');

	isToday && (defaultDayFormat = formatMessage(messages.today));
	isTomorrow && (defaultDayFormat = formatMessage(messages.tomorrow));
	isYesterday && (defaultDayFormat = formatMessage(messages.yesterday));

	return defaultDayFormat;
};

export const getTimeInSlaFriendlierFormat = (
	timeInEpochMillis: number | null | undefined,
	userTimezone: string,
	formatMessage: unknown,
) => {
	if (timeInEpochMillis) {
		const breachTimeTz = utcToZonedTime(timeInEpochMillis, userTimezone);
		return `${getMatchingDay(breachTimeTz, userTimezone, formatMessage)} ${format(
			breachTimeTz,
			'hh:mm a',
			{
				timeZone: userTimezone,
			},
		)}`;
	}
	return '';
};

// If a cycle is ongoing, we show that, otherwise we show the last completed cycle.
// @ts-expect-error - TS2322 - Type '({ storedValue, }: GenericFieldDataTransformerInput<SlaFieldResponse>) => { fieldType: "com.atlassian.servicedesk:sd-sla-field"; value: Sla | SlaError; } | { ...; } | { ...; } | { ...; }' is not assignable to type '(arg1: GenericFieldDataTransformerInput<SlaFieldResponse>) => SlaDataSelectorProps'.
export const SlaFieldTransformer: FieldDataTransformer<typeof SLA_CUSTOM_FIELD_TYPE> = ({
	storedValue,
}) => {
	const { value: responseValue } = storedValue;
	const [{ data: loggedInUser }] = useUserSubscriber();
	const userTimezone = loggedInUser?.timeZone || 'America/Toronto';
	const { formatMessage, formatDate } = useIntl();
	let locale = useLocale();
	if (locale === undefined) {
		locale = 'en_US';
	}
	const localeString = locale.replace('_', '-');

	const dateTimeFormat = new Intl.DateTimeFormat(localeString, { hour: 'numeric' });
	const isBrowserLocale24h = () => !dateTimeFormat.format(0).match(/[A/P]M/);

	const getMatchingDayForLocale = (receivedDate: Date, timeInEpochMillis: number) => {
		const [today, tomorrow, yesterday] = getDays(userTimezone);

		const isSame = (dateOne: Date, dateTwo: Date) =>
			dateOne.getDate() === dateTwo.getDate() &&
			dateOne.getMonth() === dateTwo.getMonth() &&
			dateOne.getFullYear() === dateTwo.getFullYear();

		const isToday = isSame(today, receivedDate);
		const isTomorrow = isSame(tomorrow, receivedDate);
		const isYesterday = isSame(yesterday, receivedDate);

		let defaultDayFormat = formatDate(timeInEpochMillis, {
			timeZone: userTimezone,
			day: '2-digit',
			month: 'short',
		});

		isToday && (defaultDayFormat = formatMessage(messages.today));
		isTomorrow && (defaultDayFormat = formatMessage(messages.tomorrow));
		isYesterday && (defaultDayFormat = formatMessage(messages.yesterday));

		return defaultDayFormat;
	};

	const getTimeInSlaFriendlierFormatForLocale = (timeInEpochMillis: number | null | undefined) => {
		if (timeInEpochMillis) {
			const breachTimeTz = utcToZonedTime(timeInEpochMillis, userTimezone);
			return `${getMatchingDayForLocale(breachTimeTz, timeInEpochMillis)} ${formatDate(
				timeInEpochMillis,
				{
					hour12: !isBrowserLocale24h(),
					timeZone: userTimezone,
					hour: '2-digit',
					minute: '2-digit',
				},
			)}`;
		}
		return '';
	};

	const getTimeRemainingInMinutes = (timeInHourSpaceMinutes: string): number => {
		const isTimeInMinus = timeInHourSpaceMinutes.indexOf('-') === 0;
		const absoluteTime = isTimeInMinus
			? timeInHourSpaceMinutes.substring(1)
			: timeInHourSpaceMinutes;

		const millis = parseInt(absoluteTime, 10);
		const totalMinutes = millis / (1000 * 60);
		return isTimeInMinus ? -1 * totalMinutes : totalMinutes;
	};

	const getPercentageOfTimeTaken = (goalTime: string, remainingTime: string) => {
		const goalTimeInMinutes = getTimeRemainingInMinutes(goalTime);
		const remainingTimeInMinutes = getTimeRemainingInMinutes(remainingTime);
		let percentage = (goalTimeInMinutes - remainingTimeInMinutes) / goalTimeInMinutes;
		// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
		percentage = (percentage * 100).toFixed(2);
		// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
		return Math.round(parseInt(percentage, 10)).toString();
	};

	const getBreachTimeInLocalStringForLocale = (breachTimeInEpochMillis: number): string => {
		if (breachTimeInEpochMillis) {
			const BREACH_DATE = utcToZonedTime(breachTimeInEpochMillis, userTimezone);
			return `${formatDate(breachTimeInEpochMillis, {
				hour12: !isBrowserLocale24h(),
				timeZone: userTimezone,
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			})} GMT${format(BREACH_DATE, 'xx', { timeZone: userTimezone })}`;
		}
		return `${formatMessage(messages.notApplicable)}`;
	};

	const getHoverContent = (
		closed: boolean,
		remainingTimeMillis: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		breachTime: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		goalTimeMillis: any,
		goalTimeLongFriendly: string,
		remainingTimeFriendly: string,
	): string => {
		const isTimeInMinus = remainingTimeFriendly.indexOf('-') === 0;
		let remainingTimeString;
		let timeStatus;
		if (isTimeInMinus) {
			remainingTimeString = remainingTimeFriendly.substring(1);
			timeStatus = formatMessage(messages.past);
		} else {
			remainingTimeString = remainingTimeFriendly;
			timeStatus = formatMessage(messages.left);
		}

		if (closed === true) {
			timeStatus = isTimeInMinus ? formatMessage(messages.breached) : formatMessage(messages.met);
			const percentage = getPercentageOfTimeTaken(goalTimeMillis, remainingTimeMillis);
			const hoverContentCompleted = `${
				isTimeInMinus ? `-${remainingTimeString}` : remainingTimeString
			} ${timeStatus ?? ''} ${String.fromCodePoint(
				0x2022,
			)} ${percentage}% of ${goalTimeLongFriendly} ${String.fromCodePoint(
				0x2022,
			)} ${getBreachTimeInLocalStringForLocale(breachTime)}`;
			return hoverContentCompleted;
		}

		const hoverContent = `${remainingTimeString} ${timeStatus ?? ''} ${String.fromCodePoint(
			0x2022,
		)} ${formatMessage(messages.due) ?? ''}: ${getBreachTimeInLocalStringForLocale(breachTime)}`;

		return hoverContent;
	};

	const friendlierHoverContent = (
		closed: boolean,
		remainingTimeMillis: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		breachTime: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		goalTimeMillis: any,
		goalTimeLongFriendly: string,
		remainingTimeFriendly: string,
		slaDisplayFormat: SLA_DISPLAY_FORMAT,
	) =>
		slaDisplayFormat === NEW_SLA_FORMAT
			? getHoverContent(
					closed,
					remainingTimeMillis,
					breachTime,
					goalTimeMillis,
					goalTimeLongFriendly,
					remainingTimeFriendly,
				)
			: undefined;

	// @ts-expect-error - TS2339 - Property 'errorMessage' does not exist on type 'Sla | SlaError'.
	if (responseValue && responseValue.errorMessage) {
		return {
			fieldType: SLA_CUSTOM_FIELD_TYPE,
			value: responseValue,
		};
	}

	if (
		responseValue &&
		// @ts-expect-error - TS2339 - Property 'ongoingCycle' does not exist on type 'Sla | SlaError'.
		responseValue.ongoingCycle &&
		// @ts-expect-error - TS2339 - Property 'ongoingCycle' does not exist on type 'Sla | SlaError'.
		isOngoingCycleValid(responseValue.ongoingCycle)
	) {
		const {
			paused,
			remainingTime,
			breached,
			breachTime,
			goalDuration,
			elapsedTime,
			withinCalendarHours,
			// @ts-expect-error - TS2339 - Property 'ongoingCycle' does not exist on type 'Sla | SlaError'.
		} = responseValue.ongoingCycle;
		const slaDisplayFormat: SLA_DISPLAY_FORMAT =
			// @ts-expect-error - TS2339 - Property 'slaDisplayFormat' does not exist on type 'Sla | SlaError'.
			responseValue.slaDisplayFormat ?? NEW_SLA_FORMAT;
		// When an SLA is paused due to being outside of working hours, the paused value is false
		const status = paused || !withinCalendarHours ? PAUSED : ONGOING;
		const timeRemainingInMinutes = Math.floor(remainingTime.millis / 60000);

		const breachTimeFriendly = breachTime ? breachTime.friendly : undefined;
		const timeInFriendlyFormat = () =>
			getTimeInSlaFriendlierFormatForLocale(breachTime?.epochMillis).toString();

		return {
			fieldType: SLA_CUSTOM_FIELD_TYPE,
			value: {
				status,
				timeRemainingFriendly:
					slaDisplayFormat === NEW_SLA_FORMAT ? timeInFriendlyFormat() : remainingTime.friendly,
				isBreached: breached,
				timeRemainingInMinutes,
				breachTime: breachTimeFriendly,
				elapsedTime: elapsedTime.friendly,
				goalDuration: goalDuration.friendly,
				withinCalendarHours,
				hoverContent: friendlierHoverContent(
					false,
					`${remainingTime.millis}`,
					breachTime?.epochMillis,
					goalDuration.millis,
					goalDuration.friendly,
					remainingTime.friendly,
					slaDisplayFormat,
				),
				slaDisplayFormat,
			},
		};
	}
	if (
		responseValue &&
		// @ts-expect-error - TS2339 - Property 'completedCycles' does not exist on type 'Sla | SlaError'.
		responseValue.completedCycles &&
		// @ts-expect-error - TS2339 - Property 'completedCycles' does not exist on type 'Sla | SlaError'.
		responseValue.completedCycles.length !== 0
	) {
		const slaDisplayFormat: SLA_DISPLAY_FORMAT =
			// @ts-expect-error - TS2339 - Property 'slaDisplayFormat' does not exist on type 'Sla | SlaError'.
			responseValue.slaDisplayFormat ?? NEW_SLA_FORMAT;
		// @ts-expect-error - TS2339 - Property 'completedCycles' does not exist on type 'Sla | SlaError'.
		const lastCompletedCycle = getLastCompletedCycle(responseValue.completedCycles);

		if (!isCompletedCycleValid(lastCompletedCycle)) {
			return {
				fieldType: SLA_CUSTOM_FIELD_TYPE,
			};
		}
		const timeRemainingInMinutes = Math.floor(lastCompletedCycle.remainingTime.millis / 60000);

		const { remainingTime, breached, goalDuration, elapsedTime, stopTime, breachTime } =
			lastCompletedCycle;

		const timeInFriendlyFormat = () =>
			getTimeInSlaFriendlierFormatForLocale(stopTime.epochMillis).toString();

		return {
			fieldType: SLA_CUSTOM_FIELD_TYPE,
			value: {
				status: COMPLETED,
				timeRemainingFriendly:
					slaDisplayFormat === NEW_SLA_FORMAT ? timeInFriendlyFormat() : remainingTime.friendly,
				isBreached: breached,
				timeRemainingInMinutes,
				// A completed cycle doesn't have a breachTime value
				breachTime: undefined,
				elapsedTime: elapsedTime.friendly,
				goalDuration: goalDuration.friendly,
				withinCalendarHours: undefined,
				hoverContent: friendlierHoverContent(
					true,
					`${remainingTime.millis}`,
					breachTime?.epochMillis,
					`${goalDuration.millis}`,
					goalDuration.friendly,
					remainingTime.friendly,
					slaDisplayFormat,
				),
				slaDisplayFormat,
			},
		};
	}

	return {
		fieldType: SLA_CUSTOM_FIELD_TYPE,
	};
};

export const serviceEntityFieldTransformer: FieldDataTransformer<
	typeof SERVICE_ENTITY_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: SERVICE_ENTITY_CUSTOM_FIELD_TYPE,
	value: value ? value.map((obj) => obj.id) : [],
});

export const cmdbFieldTransformer: FieldDataTransformer<typeof CMDB_OBJECT_CUSTOM_FIELD_TYPE> = ({
	storedValue: { value },
}) => ({
	fieldType: CMDB_OBJECT_CUSTOM_FIELD_TYPE,
	value,
});

export const respondersFieldTransformer: FieldDataTransformer<
	typeof RESPONDERS_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: RESPONDERS_CUSTOM_FIELD_TYPE,
	value,
});

export const majorIncidentFieldTransformer: FieldDataTransformer<
	typeof MAJOR_INCIDENT_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: MAJOR_INCIDENT_CUSTOM_FIELD_TYPE,
	value,
});

export const multiCheckBoxesFieldTransformer: FieldDataTransformer<
	typeof MULTI_CHECKBOX_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: MULTI_CHECKBOX_CUSTOM_FIELD_TYPE,
	value: value || [],
});

export const multiSelectFieldTransformer: FieldDataTransformer<
	typeof MULTI_SELECT_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: MULTI_SELECT_CUSTOM_FIELD_TYPE,
	value: value || [],
});

export const multiUserPickerTransformer: FieldDataTransformer<
	typeof MULTI_USER_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: MULTI_USER_CUSTOM_FIELD_TYPE,
	value: value
		? value.map((user) => ({
				name: user.displayName,
				email: user.emailAddress,
				avatarUrl: user.avatarUrls['48x48'],
			}))
		: [],
});

export const multiVersionFieldTransformer: FieldDataTransformer<
	typeof MULTI_VERSION_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: MULTI_VERSION_CUSTOM_FIELD_TYPE,
	value: value
		? value.map((version) => ({
				value: version.name,
				self: version.self,
				id: version.id,
			}))
		: [],
});

export const multiGroupPickerTransformer: FieldDataTransformer<
	typeof MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE,
	value: value
		? value.map((group) => ({
				value: group.name,
				self: group.self,
				id: group.groupId,
			}))
		: [],
});

export const entitlementFieldTransformer: FieldDataTransformer<
	typeof ENTITLEMENT_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: ENTITLEMENT_CUSTOM_FIELD_TYPE,
	value,
});

export const sentimentFieldTransformer: FieldDataTransformer<
	typeof SENTIMENT_CUSTOM_FIELD_TYPE
> = ({ storedValue: { value } }) => ({
	fieldType: SENTIMENT_CUSTOM_FIELD_TYPE,
	value,
});
