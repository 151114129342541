import { useMemo } from 'react';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { priorityGroupResource } from '@atlassian/jira-router-resources-service-desk-queues/src/services';
import type {
	CategorizedGroupData,
	GroupWithQueues,
} from '@atlassian/jira-router-resources-service-desk-queues/src/types';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	type RouteResourceError,
	type RouteResourceUpdater,
	useResource,
} from '@atlassian/react-resource-router';
import { useCurrentCategory, useProjectKey } from '../../common/utils';
import { useCategorizedNavItems, useCategorizedQueues } from '../../main';
import {
	createGroup,
	deleteGroup as callServerDeleteGroup,
	updateGroup as callServerUpdateGroup,
	updateQueueGroupsByGroupId as callServerUpdateQueueGroupsByGroupId,
} from '../../services/priority-group';
import type {
	GroupUpdateResult,
	NavItem,
	NavItemGroup,
	NormalizedPriorityGroup,
	Queue,
	QueueGroupsUpdateResults,
	QueueResourceCustomContext,
} from '../../types';

const updateGroup = (
	updatedGroup: {
		id: string;
		name: string;
		version?: number;
		queueIds: number[];
	},
	groups: GroupWithQueues[],
) =>
	groups.map((g) =>
		g.id === updatedGroup.id
			? {
					...g,
					name: updatedGroup.name,
					version: updatedGroup.version,
					queues: updatedGroup.queueIds,
				}
			: g,
	);

const deleteGroup = (id: string, groups: GroupWithQueues[]) =>
	groups.filter((group) => group.id !== id);

const updateQueueGroups = (updatedGroups: GroupUpdateResult[], groups: GroupWithQueues[]) =>
	groups.map((group) => {
		const matchedGroup = updatedGroups?.find((updatedGroup) => updatedGroup.group.id === group.id);
		if (matchedGroup === undefined) {
			return group;
		}
		return {
			...group,
			id: matchedGroup.group.id,
			name: matchedGroup.group.name,
			queues: matchedGroup.group.queueIds,
			version: matchedGroup.group.version,
		};
	});

const addQueueToGroupByGroupName = (
	queueId: number,
	updatedGroup: string[],
	groups: GroupWithQueues[],
) =>
	groups.map((g) =>
		updatedGroup.includes(g.name) && !g.queues.includes(queueId)
			? {
					...g,
					queues: [...g.queues, queueId],
				}
			: g,
	);

const removeQueueFromGroupsByGroupName = (
	queueId: number,
	updatedGroup: string[],
	groups: GroupWithQueues[],
) =>
	groups.map((g) =>
		updatedGroup.includes(g.name)
			? {
					...g,
					queues: g.queues.filter((q) => q !== queueId),
				}
			: g,
	);

const createActions = (
	update: (getNewData: RouteResourceUpdater<CategorizedGroupData>) => void,
	category: ItsmPractice,
	projectId: string,
	cloudId: string,
) => ({
	updateQueuesInGroup: (id: string, name: string, version: number, newQueues: number[]) =>
		callServerUpdateGroup({ cloudId, id, name, version, queueIds: newQueues }).then(
			(updatedGroup) => {
				update((data) => ({
					...data,
					[category]: updateGroup(updatedGroup, data[category]),
				}));
			},
		),

	renameGroup: (id: string, newName: string, version: number, queues: number[]) =>
		callServerUpdateGroup({ cloudId, id, name: newName, version, queueIds: queues }).then(
			(updatedGroup) => {
				update((data) => ({
					...data,
					[category]: updateGroup(updatedGroup, data[category]),
				}));
			},
		),
	createGroup: (name: string) =>
		createGroup({
			name,
			category,
			projectId,
			cloudId,
			queueIds: [],
		}).then((createdGroup) => {
			update((data) => {
				const groups = data[category] || [];
				return {
					...data,
					[category]: [...groups, { ...createdGroup, queues: createdGroup.queueIds }],
				};
			});
		}),
	deleteGroup: (id: string) =>
		callServerDeleteGroup({ cloudId, id }).then(() => {
			update((data) => ({
				...data,
				[category]: deleteGroup(id, data[category]),
			}));
		}),
	addQueueToGroupByGroupName: (queueId: number, toUpdateGroups: string[]) => {
		if (toUpdateGroups.length === 0) {
			return;
		}

		update((data) => ({
			...data,
			[category]: addQueueToGroupByGroupName(
				queueId,
				toUpdateGroups,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				data[category] as GroupWithQueues[],
			),
		}));
	},
	removeQueueFromGroupsByGroupName: (queueId: number, toUpdateGroups: string[]) => {
		if (toUpdateGroups.length === 0) {
			return;
		}

		update((data) => ({
			...data,
			[category]: removeQueueFromGroupsByGroupName(queueId, toUpdateGroups, data[category]),
		}));
	},
	updateQueueGroupsByGroupId: (queueId: number, toUpdateGroups: string[]) =>
		callServerUpdateQueueGroupsByGroupId({
			cloudId,
			projectId,
			category,
			queueId,
			groupIds: toUpdateGroups,
		}).then((updatedGroups: QueueGroupsUpdateResults) => {
			update((data) => ({
				...data,
				[category]: updateQueueGroups(
					updatedGroups.results,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					data[category] as GroupWithQueues[],
				),
			}));
		}),
});

export const usePriorityGroupsActions = (): ReturnType<typeof createActions> => {
	const category = useCurrentCategory();
	const cloudId = useCloudId();
	const { data: projectContext } = useProjectContext();

	const { update } = useResource(priorityGroupResource);
	const projectId = projectContext?.projectId;

	return useMemo(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		() => createActions(update, category, projectId?.toString() as string, cloudId),
		[category, cloudId, projectId, update],
	);
};

export const usePriorityGroupData = (customContext?: QueueResourceCustomContext) =>
	useResourceWithCustomRouterContext(priorityGroupResource, customContext);

export const usePriorityGroups = (
	category: ItsmPractice,
	customContext?: QueueResourceCustomContext,
) => {
	const { data, loading, error, refresh } = usePriorityGroupData(customContext);
	const categorizedGroups = useMemo(() => (data && data[category]) ?? [], [category, data]);
	return [categorizedGroups, loading, error, refresh] as const;
};

export const useCategorizedGroupsNavItems = (
	projectKey: string,
	category: ItsmPractice,
	customContext?: QueueResourceCustomContext,
): readonly [NavItemGroup[], boolean, RouteResourceError | null, RouteResourceError | null] => {
	const [groups, groupLoading, groupError] = usePriorityGroups(category, customContext);
	const [navItems, loading, queueError] = useCategorizedNavItems(
		'',
		projectKey,
		category,
		customContext,
	);
	return useMemo(() => {
		const isLoading = loading || groupLoading;
		if (isLoading || queueError || groupError) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return [[] as NavItemGroup[], isLoading, groupError, queueError] as const;
		}
		const normalizedGroups: NavItemGroup[] = groups.map((group) => ({
			group,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			queues: group.queues
				.map((queueId) => navItems?.find((item) => Number.parseInt(item.id, 10) === queueId))
				.filter((item) => !!item) as NavItem[],
		}));

		return [normalizedGroups, isLoading, groupError, queueError] as const;
	}, [groupError, groupLoading, groups, loading, navItems, queueError]);
};

export const useNormalizedPriorityGroups = () => {
	const projectKey = useProjectKey();
	const category = useCurrentCategory();
	const [groups, groupLoading] = usePriorityGroups(category);
	const [navItems, loading, error] = useCategorizedQueues('', projectKey, category);
	return useMemo(() => {
		const isLoading = loading || groupLoading;
		if (isLoading || error) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return [[] as NormalizedPriorityGroup[], isLoading, error] as const;
		}
		const normalizedGroups: NormalizedPriorityGroup[] = groups.map((group) => ({
			group,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			queues: group.queues
				.map((queueId) => navItems?.find((item) => item.id === queueId))
				.filter((queue) => !!queue) as Queue[],
		}));

		return [normalizedGroups, isLoading, error] as const;
	}, [error, groupLoading, groups, loading, navItems]);
};
