import type { SetStateAction } from 'react';
import type {
	IssueAction,
	IssueGlance,
	IssueContext,
	IssueGlanceStatus,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types';
import type { StoreActionApi } from '@atlassian/react-sweet-state';

export const FORGE_ENTITY_TYPE = 'FORGE_ENTITY_TYPE';

type ForgeEntityType = typeof FORGE_ENTITY_TYPE;

export type ForgeGlanceType = ForgeEntityType;
export type ForgeContextType = ForgeEntityType;
export type ForgePanelType = ForgeEntityType;
export type ForgeActionType = ForgeEntityType;
export type ForgeActivityType = ForgeEntityType;

export type Glance = {
	title: string;
	label: string;
	status?: IssueGlanceStatus;
	extension: IssueGlance;
	glanceType: ForgeGlanceType;
	moduleKey: string;
	appKey: string;
};

// ContextPanels are pretty much the 'same' as glances except they appear in a different location
export type ContextPanel = {
	title: string;
	label: string;
	status?: IssueGlanceStatus;
	extension: IssueContext;
	contextPanelType: ForgeContextType;
	moduleKey: string;
	appKey: string;
};

export type Action = {
	id: string;
	title: string;
	extension: IssueAction;
	type: ForgeActionType;
};

export type ActiveAction = {
	action: Action;
	isLoading: boolean;
};

export type State = ForgeResponse & {
	activeAction: ActiveAction | null;
	isForgeLoadingFailed: boolean;
	isForgeDataComplete: boolean;
	forgeRateLimitedUntil: Millisecond;
	failedEventsCount: number;
};

export type StoreApi = StoreActionApi<State>;

export type ForgeServiceActions = {
	setForge: (
		state: ForgeResponse & { isForgeLoadingFailed: boolean; isForgeDataComplete: boolean },
	) => void;
	setForgeLoadingFailed: () => void;
} & ForgeRateLimitingActions;

type ForgeRateLimitingActions = {
	/**
	 * Calculate the throttle basing on current time and the number of failed events occurred.
	 */
	isForgeRateLimited: () => boolean;
	setFailedEventsCount: (param: SetStateAction<number>) => void;
};
