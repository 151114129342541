import {
	type FORGE_USERS_GQL_FIELD,
	ISSUE_FIELD_USER_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';
import isFieldMissingRenderer from './helpers/is-field-missing-renderer.tsx';
import { transformJiraUserToLegacyServerUser } from './user-node-transformer';

export const transformAggForgeUsersToLegacyField: AggTransformerFunction<
	typeof FORGE_USERS_GQL_FIELD
> = (node) => {
	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	const value = node.selectedUsersConnection?.edges?.length
		? transformAggConnection(node.selectedUsersConnection, transformJiraUserToLegacyServerUser)
		: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			configuration: null,
			items: ISSUE_FIELD_USER_CF_TYPE,
			system: null,
			custom: node.type,
			renderer: node.renderer,
			type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
		},
	};
};
